import { GlobalPosition } from "../game/models/position-models";
import { ServerCommandType } from "./server-command-types";

export const serializeDoUseAction = (
  globalPos: GlobalPosition
): ArrayBuffer => {
  return serializeClickMouseAction(globalPos, ServerCommandType.DoUseAction);
};

export const serializeLongWalkAction = (
  globalPos: GlobalPosition
): ArrayBuffer => {
  return serializeClickMouseAction(
    globalPos,
    ServerCommandType.DoLongWalkAction
  );
};

const serializeClickMouseAction = (
  globalPos: GlobalPosition,
  commandType: ServerCommandType
): ArrayBuffer => {
  const command = new ArrayBuffer(5);
  const view = new DataView(command);
  view.setUint8(0, commandType);
  view.setUint16(1, globalPos.getGlobalX());
  view.setUint16(3, globalPos.getGlobalY());
  return command;
};
