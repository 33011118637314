export const errorCodes = {
  UNKNOWN_ERROR: "UNKNOWN_ERROR",
  PASSWORD_NO_MATCH: "PASSWORD_NO_MATCH",
  INVALID_CREDENTIALS: "INVALID_CREDENTIALS",
  PLAYER_NOT_FOUND: "PLAYER_NOT_FOUND",
};

const publicErrorMessages: { [key: string]: string } = {
  UNKNOWN_ERROR: "Unknown error",
  PASSWORD_NO_MATCH: "Passwords don't match",
  INVALID_CREDENTIALS: "Invalid credentials",
  PLAYER_NOT_FOUND: "Player not found",
};

export const getPublicErrorMessage = (errorCode: string): string => {
  return publicErrorMessages[errorCode] || publicErrorMessages.UNKNOWN_ERROR;
};
