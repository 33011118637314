import { NewExpDto } from "../game/models/deserialized-models";
import { CommandDeserializer } from "./CommandDeserializer";
import { readExp } from "./helpers/backend-data-reader-helper";

export const deserializeNewExp = (data: Uint8Array): NewExpDto => {
  const deserializer = new CommandDeserializer(data);
  return {
    attackerId: deserializer.getUint16(),
    gainedExp: deserializer.getUint64(),
    expDto: readExp(deserializer),
  };
};
