import { ServerCommandType } from "./server-command-types";

export const serializeMessage = (strMessage: string): ArrayBuffer => {
  const textBytes = new TextEncoder().encode(strMessage);
  const command = new ArrayBuffer(1 + textBytes.length);
  const view = new DataView(command);
  view.setUint8(0, ServerCommandType.SendMessage);
  textBytes.forEach((b, i) => view.setUint8(1 + i, b));
  return command;
};
