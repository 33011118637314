import { AddItemDto } from "../game/models/deserialized-models";
import { CommandDeserializer } from "./CommandDeserializer";
import { readGlobalPos, readItem } from "./helpers/backend-data-reader-helper";

export const deserializeRemoveItem = (data: Uint8Array): number => {
  const deserializer = new CommandDeserializer(data);
  return deserializer.getUint16();
};

export const deserializeAddItem = (data: Uint8Array): AddItemDto => {
  const deserializer = new CommandDeserializer(data);

  return {
    position: readGlobalPos(deserializer),
    item: readItem(deserializer),
  };
};
