import { TILE_SIZE } from "../game/GameConfig";
import { SpriteConfig, SpriteConfigs } from "../game/sprites/SpriteConfig";
import { MEField, MEPosition } from "./mapEditorModels";
import { MapEditorScene } from "./MapEditorScene";

export interface ObjectData {
  typeId: number;
  image: Phaser.GameObjects.Image;
}

export interface MonsterData extends ObjectData {
  respawnTimeInSec: number;
}

class MapEditorField {
  private scene: MapEditorScene;
  private spriteConfigs: SpriteConfigs;
  private drawX: number;
  private drawY: number;
  private x: number;
  private y: number;
  private rect: Phaser.GameObjects.Rectangle;

  private tileData: ObjectData;
  private monsterData: MonsterData | null = null;
  private obstacleData: ObjectData | null = null;

  private highlight: Phaser.GameObjects.Image;

  private items: any[];

  constructor(
    i: number,
    j: number,
    scene: MapEditorScene,
    tileTypeId: number,
    spriteCongis: SpriteConfigs,
    items: any[] = []
  ) {
    this.scene = scene;
    this.items = items;
    this.spriteConfigs = spriteCongis;

    this.x = i;
    this.y = j;

    this.drawX = i * TILE_SIZE + TILE_SIZE / 2;
    this.drawY = j * TILE_SIZE + TILE_SIZE / 2;

    this.rect = scene.add
      .rectangle(this.drawX, this.drawY, TILE_SIZE, TILE_SIZE, 0x000000, 0.0)
      .setStrokeStyle(4, 0xffffff, 0.5)
      .setDepth(1000);

    this.tileData = {
      typeId: tileTypeId,
      image: scene.add
        .image(
          this.drawX,
          this.drawY,
          this.spriteConfigs.tiles.getSpriteKey(tileTypeId)
        )
        .setDisplaySize(TILE_SIZE, TILE_SIZE)
        .setDepth(0),
    };

    this.highlight = scene.add
      .image(
        this.drawX,
        this.drawY,
        this.spriteConfigs.tiles.getSpriteKey(0x03)
      )
      .setDepth(10)
      .setDisplaySize(TILE_SIZE, TILE_SIZE)
      .setVisible(false);
  }

  highlightTile(tileId: number) {
    this.highlightObject(tileId, this.spriteConfigs.tiles, 0);
  }

  highlightMonster(monsterId: number) {
    this.highlightObject(monsterId, this.spriteConfigs.creatures, 10);
  }

  highlightObstacle(obstacleId: number) {
    this.highlightObject(obstacleId, this.spriteConfigs.obstacles, 10);
  }

  setAsEditing() {
    this.rect.setFillStyle(0xffff00, 0.5);
  }

  removeHighlight() {
    this.highlight.setVisible(false);
  }

  removeEditing() {
    this.rect.setFillStyle(0x00000, 0);
  }

  setLineWidth(width: number) {
    this.rect.setStrokeStyle(width, 0xffffff, 0.5);
  }

  setTile(tileTypeId: number) {
    this.tileData.typeId = tileTypeId;
    this.tileData.image
      .setTexture(this.spriteConfigs.tiles.getSpriteKey(tileTypeId))
      .setDisplaySize(TILE_SIZE, TILE_SIZE);
  }

  setMonster(monsterTypeId: number | null) {
    if (monsterTypeId == null) {
      this.monsterData?.image.destroy();
      this.monsterData = null;
    } else {
      const sprite = this.spriteConfigs.creatures.getSprite(monsterTypeId);
      if (!this.monsterData) {
        this.monsterData = {
          typeId: monsterTypeId,
          respawnTimeInSec: 10,
          image: this.scene.add
            .image(this.drawX, this.drawY + sprite.yOffset, sprite.key)
            .setDisplaySize(
              TILE_SIZE * sprite.xScale,
              TILE_SIZE * sprite.yScale
            )
            .setDepth(1),
        };
      } else {
        this.monsterData.typeId = monsterTypeId;
        this.monsterData.image.setTexture(sprite.key);
      }
    }
  }

  setObstacle(obstacleTypeId: number | null) {
    if (obstacleTypeId == null) {
      this.obstacleData?.image.destroy();
      this.obstacleData = null;
    } else {
      const sprite = this.spriteConfigs.obstacles.getSprite(obstacleTypeId);
      if (!this.obstacleData) {
        this.obstacleData = {
          typeId: obstacleTypeId,
          image: this.scene.add
            .image(this.drawX, this.drawY + sprite.yOffset, sprite.key)
            .setDisplaySize(
              TILE_SIZE * sprite.xScale,
              TILE_SIZE * sprite.yScale
            )
            .setDepth(1),
        };
      } else {
        this.obstacleData.typeId = obstacleTypeId;
        this.obstacleData.image.setTexture(sprite.key);
      }
    }
  }

  update(field: MEField) {
    if (field.monster) {
      this.monsterData!.respawnTimeInSec = field.monster.respawnTimeInSec;
    } else {
      this.setMonster(null);
    }
  }

  clear() {
    this.setTile(0);
    this.setMonster(null);
    this.setObstacle(null);
  }

  getPosition(): MEPosition {
    return {
      x: this.x,
      y: this.y,
    };
  }

  getTileTypeId(): number {
    return this.tileData.typeId;
  }

  representation(): MEField {
    return {
      position: this.getPosition(),
      tileTypeId: this.tileData.typeId,
      obstacleTypeId: this.obstacleData ? this.obstacleData.typeId : null,
      monster: this.monsterData
        ? {
            typeId: this.monsterData.typeId,
            respawnTimeInSec: this.monsterData.respawnTimeInSec,
          }
        : null,
    };
  }

  private highlightObject(
    typeId: number,
    spriteConfig: SpriteConfig,
    depth: number
  ) {
    const sprite = spriteConfig.getSprite(typeId);
    this.highlight
      .setTexture(sprite.key)
      .setDisplaySize(TILE_SIZE * sprite.xScale, TILE_SIZE * sprite.yScale)
      .setPosition(this.drawX, this.drawY + sprite.yOffset)
      .setDepth(depth)
      .setVisible(true);
  }
}

export default MapEditorField;
