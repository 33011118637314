import { DeadDto } from "../game/models/deserialized-models";
import { CommandDeserializer } from "./CommandDeserializer";
import { readGlobalPos, readItem } from "./helpers/backend-data-reader-helper";

export const deserializeDead = (data: Uint8Array): DeadDto => {
  const deserializer = new CommandDeserializer(data);

  return {
    deadPlayerId: deserializer.getUint16(),
    position: readGlobalPos(deserializer),
    item: readItem(deserializer),
  };
};
