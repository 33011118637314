const map = new Map<number, number>();
map.set(0, 0x000000);
map.set(0.1, 0x660000); // Ciemny bordowy zamiast jaskrawego czerwonego
map.set(0.2, 0xcc0000); // Mniej intensywny czerwony
map.set(0.3, 0xcc6600); // Ciemniejszy pomarańczowy
map.set(0.4, 0xcc9933); // Ciemniejszy złoty
map.set(0.5, 0xcccc00); // Mniej jaskrawy żółty
map.set(0.6, 0xb3cc00); // Zgaszony zielono-żółty
map.set(0.7, 0x66cc66); // Zgaszony jasnozielony
map.set(0.8, 0x33cc33); // Mniej intensywny jasnozielony
map.set(0.9, 0x00cc00); // Mniej jaskrawy zielony
map.set(1, 0x009900); // Ciemniejszy zielony

export const getColorHex = (x: number = 1): number => {
  return map.get(x)!;
};

export const getColorHexStr = (x: number = 1): string => {
  return numberToHexColor(map.get(x)!);
};

export const getRedColorStr = (): string => {
  return getColorHexStr(0.2);
};

export const getGreenColorStr = (): string => {
  return getColorHexStr(1);
};

export const getOrangeColorStr = (): string => {
  return getColorHexStr(0.3);
};

export const getYellowColorStr = (): string => {
  return getColorHexStr(0.5);
};

const numberToHexColor = (num: number): string => {
  const hex = num.toString(16);
  return "#" + hex.padStart(6, "0");
};
