export class CommandDeserializer {
  private dataView: DataView;
  private offset: number;

  constructor(data: Uint8Array) {
    this.dataView = new DataView(data.buffer);
    this.offset = 0;
  }

  getUint8(): number {
    return this.getValueIntAndIncreaseOffset(this.dataView.getUint8, 1);
  }

  getInt8(): number {
    return this.getValueIntAndIncreaseOffset(this.dataView.getInt8, 1);
  }

  getUint16(): number {
    return this.getValueIntAndIncreaseOffset(this.dataView.getUint16, 2);
  }

  getInt16(): number {
    return this.getValueIntAndIncreaseOffset(this.dataView.getInt16, 2);
  }

  getUint64(): BigInt {
    const data = this.dataView.getBigUint64(this.offset);
    this.offset += 8;
    return data;
  }

  getShortText(): string {
    return this.getText(this.dataView.getUint8);
  }

  getLongText(): string {
    return this.getText(this.dataView.getUint16);
  }

  getOffset(): number {
    return this.offset;
  }

  getByteLength(): number {
    return this.dataView.byteLength;
  }

  private getText(getLengthFunc: GetValueFunction): string {
    const length = getLengthFunc.call(this.dataView, this.offset);
    this.offset += getLengthFunc === this.dataView.getUint8 ? 1 : 2;
    const stringBytes = new Uint8Array(
      this.dataView.buffer,
      this.offset,
      length
    );
    this.offset += length;
    return new TextDecoder("utf-8").decode(stringBytes);
  }

  private getValueIntAndIncreaseOffset(
    getValueFunc: GetValueFunction,
    offsetSize: number
  ): number {
    const data = getValueFunc.call(this.dataView, this.offset);
    this.offset += offsetSize;
    return data;
  }
}

type GetValueFunction = (byteOffset: number) => number;
