import { TILE_SIZE } from "../GameConfig";
import { MainScene } from "../MainScene";
import {
  getColorHex,
  getColorHexStr,
  getGreenColorStr,
  getOrangeColorStr,
  getRedColorStr,
  getYellowColorStr,
} from "../helpers/color-resolver";
import { Direction, standDirectionMap, walks } from "../models/models";
import { Vector } from "../models/position-models";
import { Sprite } from "../sprites/SpriteConfig";

const BAR_WIDTH = 100;
const BAR_HEIGHT = 8;
const OUTLINE_THICKNESS = 4;

export abstract class AbstractCreature {
  protected scene: MainScene;
  protected spriteData: Sprite;

  protected characterSprite: Phaser.GameObjects.Sprite;
  private nameSprite!: Phaser.GameObjects.Text;
  private healthBarSprite: Phaser.GameObjects.Graphics;

  private id: number;
  private walkTimeInMillis: number;
  private walkStartTime: number;
  private walkDirection: Direction | null;
  private standDirection: Direction;

  private allHp!: number;
  private currentHp!: number;

  private textsMap: Map<string, Phaser.GameObjects.Text>;

  constructor(
    scene: MainScene,
    pixelPos: Vector,
    name: string,
    standDirection: Direction,
    localY: number,
    id: number,
    allHp: number,
    currentHp: number,
    spriteData: Sprite
  ) {
    this.scene = scene;
    this.spriteData = spriteData;
    this.id = id;
    this.walkTimeInMillis = 0;
    this.walkStartTime = 0;
    this.walkDirection = null;
    this.standDirection = standDirection;
    const spritePos = this.spritePos(pixelPos);

    this.characterSprite = scene.add
      .sprite(spritePos.x, spritePos.y, this.spriteData.key)
      .setDisplaySize(
        TILE_SIZE * this.spriteData.xScale,
        TILE_SIZE * this.spriteData.yScale
      )
      .setFrame(standDirectionMap.get(standDirection)!)
      .setDepth(localY);

    const nickPos = this.nickPos(pixelPos);

    this.nameSprite = scene.add
      .text(nickPos.x, nickPos.y, name, {
        align: "center",
        fontSize: "24px",
        fontFamily: '"Press Start 2P"',
      })
      .setOrigin(0.5, 0.5)
      .setStroke("#000000", 8)
      .setDepth(localY);

    this.healthBarSprite = scene.add.graphics();
    const healthBarPos = this.healthBarPos(pixelPos);
    this.healthBarSprite.setX(healthBarPos.x);
    this.healthBarSprite.setY(healthBarPos.y);
    this.healthBarSprite.setDepth(localY);

    this.updateHP(allHp, currentHp);
    this.textsMap = new Map();
  }

  updateWalk(time: number, delta: number) {
    if (this.isWalkingActive(time)) {
      const dx =
        walks.get(this.walkDirection!)!.xFactor *
        TILE_SIZE *
        (delta / this.walkTimeInMillis);
      const dy =
        walks.get(this.walkDirection!)!.yFactor *
        TILE_SIZE *
        (delta / this.walkTimeInMillis);

      const ds = { x: dx, y: dy };
      this.moveByDs(ds);
    }
  }

  rotate(newStandDirection: Direction) {
    this.standDirection = newStandDirection;
    this.characterSprite.setFrame(standDirectionMap.get(newStandDirection)!);
  }

  startWalk(
    walkTimeInMillis: number,
    walkDirection: Direction,
    anim: string,
    pixelPos: Vector,
    localY: number
  ) {
    this.updatePos(pixelPos, localY);

    this.walkTimeInMillis = walkTimeInMillis;
    this.walkStartTime = this.scene.time.now;
    this.walkDirection = walkDirection;
    this.characterSprite.anims.play(`${this.spriteData.key}_${anim}`, true);
    this.standDirection = walkDirection;
  }

  finishWalk(
    standDirection: Direction | null,
    pixelPos: Vector,
    localY: number
  ) {
    this.characterSprite.anims.stop();
    this.standDirection = standDirection || this.standDirection;
    this.characterSprite.setFrame(standDirectionMap.get(this.standDirection)!);
    this.walkDirection = null;
    this.updatePos(pixelPos, localY);
  }

  showSound(sound: string) {
    this.showText(sound, getOrangeColorStr(), 400, 80);
  }

  applyHpChange(hpDifference: number, allHp: number, currentHp: number) {
    this.showText(
      Math.abs(hpDifference).toString(),
      hpDifference > 0 ? getGreenColorStr() : getRedColorStr()
    );
    this.updateHP(allHp, currentHp);
  }

  gainExp(gainedExp: BigInt) {
    const refPos = this.getPixelPos();
    const startX = refPos.x;
    const startY = refPos.y - 55;

    const expText = this.scene.add
      .text(startX, startY, "+" + gainedExp.toString(), {
        align: "center",
        fontSize: "24px",
        fontFamily: '"Press Start 2P"',
        color: getYellowColorStr(),
      })
      .setOrigin(0.5, 0.5)
      .setStroke("#000000", 8)
      .setDepth(100);

    this.scene.tweens.add({
      targets: expText,
      scale: { from: 1, to: 1.1 },
      duration: 750,
      ease: "Power2",
      onComplete: () => {
        expText.destroy();
      },
    });
  }

  getId() {
    return this.id;
  }

  getPixelPos(): Vector {
    return {
      x: this.characterSprite.x,
      y: this.characterSprite.y - this.spriteData.yOffset,
    };
  }

  destroy() {
    this.characterSprite.destroy(true);
    this.nameSprite.destroy(true);
    this.healthBarSprite.destroy(true);
    this.textsMap.forEach((v, k) => v.destroy(true));
  }

  getStandDirection() {
    return this.standDirection;
  }

  protected moveByDs(ds: Vector): void {
    this.characterSprite.setX(this.characterSprite.x - ds.x);
    this.characterSprite.setY(this.characterSprite.y - ds.y);

    this.nameSprite.setX(this.nameSprite.x - ds.x);
    this.nameSprite.setY(this.nameSprite.y - ds.y);

    this.healthBarSprite.setX(this.healthBarSprite.x - ds.x);
    this.healthBarSprite.setY(this.healthBarSprite.y - ds.y);

    this.textsMap.forEach((v, k) => {
      v.setX(v.x - ds.x);
      v.setY(v.y - ds.y);
    });
  }

  protected updatePos(pixelPos: Vector, localY: number) {
    const spritePos = this.spritePos(pixelPos);
    this.characterSprite.setX(spritePos.x);
    this.characterSprite.setY(spritePos.y);
    this.characterSprite.setDepth(localY);

    const nickPos = this.nickPos(pixelPos);
    this.nameSprite.setX(nickPos.x);
    this.nameSprite.setY(nickPos.y);
    this.nameSprite.setDepth(localY);

    const healthBarPos = this.healthBarPos(pixelPos);
    this.healthBarSprite.setX(healthBarPos.x);
    this.healthBarSprite.setY(healthBarPos.y);
    this.healthBarSprite.setDepth(localY);
  }

  private isWalkingActive(time: number) {
    return (
      this.walkDirection && time - this.walkStartTime <= this.walkTimeInMillis
    );
  }

  private updateHP(allHp: number, currentHp: number) {
    this.allHp = allHp;
    this.currentHp = currentHp;
    const hpFactor = Math.round((this.currentHp / this.allHp) * 10) / 10;
    this.fillHealthBar(hpFactor);
    this.nameSprite.setColor(getColorHexStr(hpFactor));
  }

  private fillHealthBar(hpFactor: number) {
    this.healthBarSprite.clear();
    this.healthBarSprite.fillStyle(0x000000, 1);
    this.healthBarSprite.fillRect(
      -OUTLINE_THICKNESS,
      -OUTLINE_THICKNESS,
      BAR_WIDTH + OUTLINE_THICKNESS * 2,
      BAR_HEIGHT + OUTLINE_THICKNESS * 2
    );

    this.healthBarSprite.fillStyle(getColorHex(hpFactor), 1);
    this.healthBarSprite.fillRect(0, 0, BAR_WIDTH * hpFactor, BAR_HEIGHT);
  }

  private spritePos(pos: Vector) {
    return {
      x: pos.x,
      y: pos.y + this.spriteData.yOffset,
    };
  }

  private nickPos(pos: Vector) {
    return {
      x: pos.x,
      y:
        pos.y +
        this.spriteData.yOffset -
        this.spriteData.yScale * (TILE_SIZE / 2) -
        25 -
        30,
    };
  }

  private healthBarPos(pos: Vector) {
    return {
      x: pos.x - BAR_WIDTH / 2,
      y:
        pos.y +
        this.spriteData.yOffset -
        this.spriteData.yScale * (TILE_SIZE / 2) -
        25,
    };
  }

  private showText(
    text: string,
    color: string,
    delay: number = 200,
    yOffset: number = 40
  ) {
    const refPos = this.getPixelPos();
    const startX = refPos.x;
    const startY = refPos.y - TILE_SIZE / 2;

    const textComponent = this.scene.add
      .text(startX, startY, text, {
        align: "center",
        fontSize: "24px",
        fontFamily: '"Press Start 2P"',
        color: color,
      })
      .setOrigin(0.5, 0.5)
      .setStroke("#000000", 8)
      .setDepth(100);

    const { v4: uuidv4 } = require("uuid");
    const id = uuidv4();
    this.textsMap.set(id, textComponent);

    this.scene.tweens.add({
      targets: textComponent,
      y: startY - yOffset,
      duration: 200,
      onComplete: () => {
        this.scene.time.delayedCall(delay, () => {
          this.textsMap.delete(id);
          textComponent.destroy();
        });
      },
    });
  }
}
