import { MessageDto } from "../game/models/deserialized-models";
import { CommandDeserializer } from "./CommandDeserializer";

export const deserializeGameCommunicate = (data: Uint8Array): string => {
  return new CommandDeserializer(data).getLongText();
};

export const deserializeMessage = (data: Uint8Array): MessageDto => {
  const deserializer = new CommandDeserializer(data);
  const executorId = deserializer.getUint16();
  const message = deserializer.getLongText();

  return {
    executorId: executorId,
    message: message,
  };
};
