import { NewCapacityDto } from "../game/models/deserialized-models";
import { CommandDeserializer } from "./CommandDeserializer";

export const deserializeNewCapacity = (data: Uint8Array): NewCapacityDto => {
  const deserializer = new CommandDeserializer(data);
  return {
    capacity: deserializer.getUint16(),
    loadPercentage: deserializer.getUint8(),
  };
};
