import { Scene } from "phaser";
import { EventBus } from "./eventBus";
import { Direction, walks } from "./models/models";
import { GameMap } from "./objects/GameMap";
import {
  ChunkDto,
  FinishWalkDto,
  InitDataDto,
  CreatureWithPosDto,
  StartWalkDto,
  PlayerIdAndPos,
  DeadDto,
  AddItemDto,
  NewExpDto,
  NewHealthDto,
  SoundDto,
} from "./models/deserialized-models";
import {
  GlobalPosition,
  LocalScreenPosition,
  Vector,
} from "./models/position-models";
import { SpriteConfig, SpriteConfigs } from "./sprites/SpriteConfig";
import { TILE_SIZE } from "./GameConfig";

interface MainSceneData {
  initData: InitDataDto;
  spriteConfigs: SpriteConfigs;
}

export class MainScene extends Scene {
  private initData!: InitDataDto;
  private gameMap!: GameMap;

  private tilesSpritesConfig!: SpriteConfig;
  private itemsSpritesConfig!: SpriteConfig;
  private creaturesSpritesConfig!: SpriteConfig;
  private obstaclesSpritesConfig!: SpriteConfig;

  private cursors!: Phaser.Types.Input.Keyboard.CursorKeys;

  private lastActionReadWalkTime: number = 0;
  private lastActionReadRotateTime: number = 0;

  private lastMouseDownPos: GlobalPosition | null;
  private gameDiv: HTMLElement | null;

  constructor() {
    super("MainScene");
    this.lastMouseDownPos = null;
    this.gameDiv = document.getElementById("game-container");
  }

  init(data: MainSceneData) {
    this.initData = data.initData;
    this.tilesSpritesConfig = data.spriteConfigs.tiles;
    this.itemsSpritesConfig = data.spriteConfigs.items;
    this.creaturesSpritesConfig = data.spriteConfigs.creatures;
    this.obstaclesSpritesConfig = data.spriteConfigs.obstacles;
  }

  preload() {
    this.tilesSpritesConfig.getAllSprites().forEach((tileSprite) => {
      if (tileSprite.anim) {
        this.load.spritesheet(tileSprite.key, tileSprite.spriteSrc, {
          frameWidth: TILE_SIZE,
          frameHeight: TILE_SIZE,
        });
      } else {
        this.load.image(tileSprite.key, tileSprite.spriteSrc);
      }
    });

    this.itemsSpritesConfig
      .getAllSprites()
      .forEach((itemSprite) =>
        this.load.image(itemSprite.key, itemSprite.spriteSrc)
      );

    this.creaturesSpritesConfig.getAllSprites().forEach((creatureSprite) =>
      this.load.spritesheet(creatureSprite.key, creatureSprite.spriteSrc, {
        frameWidth: TILE_SIZE,
        frameHeight: TILE_SIZE,
      })
    );

    this.obstaclesSpritesConfig
      .getAllSprites()
      .forEach((obstacleSprite) =>
        this.load.image(obstacleSprite.key, obstacleSprite.spriteSrc)
      );
  }

  create(): void {
    this.defineHumanAnims();
    this.defineAndStartTileAnims();

    this.gameMap = new GameMap(
      this,
      this.initData,
      this.tilesSpritesConfig,
      this.itemsSpritesConfig,
      this.creaturesSpritesConfig,
      this.obstaclesSpritesConfig
    );

    this.cursors = this.input.keyboard?.createCursorKeys()!;
    this.input.keyboard?.removeCapture("SPACE");

    this.input.mouse?.disableContextMenu();
    this.input.on(
      Phaser.Input.Events.POINTER_DOWN,
      this.handlePointerDown,
      this
    );
    this.input.on(
      Phaser.Input.Events.POINTER_MOVE,
      this.handlePointerMove,
      this
    );
    this.input.on(Phaser.Input.Events.POINTER_UP, this.handlePointerUp, this);

    this.registerListeners();
  }

  shutdown(): void {
    EventBus.off("show-start-walk-left");
    EventBus.off("show-start-walk-up");
    EventBus.off("show-start-walk-right");
    EventBus.off("show-start-walk-down");
    EventBus.off("show-finish-walk");
    EventBus.off("show-rotate-left");
    EventBus.off("show-rotate-up");
    EventBus.off("show-rotate-right");
    EventBus.off("show-rotate-down");
    EventBus.off("show-new-chunk");
    EventBus.off("show-player-new-spawn");
    EventBus.off("show-remove-player");
    EventBus.off("show-new-visible-player");
    EventBus.off("show-sound");
    EventBus.off("show-target-loss");
    EventBus.off("show-clear-target");
    EventBus.off("show-new-target");
    EventBus.off("show-player-dead");
    EventBus.off("show-take-old-position");
    EventBus.off("show-remove-item");
    EventBus.off("show-add-item");
    EventBus.off("show-new-exp");
    EventBus.off("show-new-health");
  }

  private registerListeners() {
    EventBus.on(
      "show-start-walk-left",
      (startWalkData: StartWalkDto) =>
        this.gameMap.startWalk(startWalkData, "walkLeft", Direction.LEFT),
      this
    );
    EventBus.on(
      "show-start-walk-up",
      (startWalkData: StartWalkDto) =>
        this.gameMap.startWalk(startWalkData, "walkUp", Direction.UP),
      this
    );
    EventBus.on(
      "show-start-walk-right",
      (startWalkData: StartWalkDto) =>
        this.gameMap.startWalk(startWalkData, "walkRight", Direction.RIGHT),
      this
    );
    EventBus.on(
      "show-start-walk-down",
      (startWalkData: StartWalkDto) =>
        this.gameMap.startWalk(startWalkData, "walkDown", Direction.DOWN),
      this
    );
    EventBus.on(
      "show-finish-walk",
      (finishWalkData: FinishWalkDto) =>
        this.gameMap.finishWalk(finishWalkData),
      this
    );
    EventBus.on(
      "show-rotate-left",
      (playerId: number) => this.gameMap.rotatePlayer(Direction.LEFT, playerId),
      this
    );
    EventBus.on(
      "show-rotate-up",
      (playerId: number) => this.gameMap.rotatePlayer(Direction.UP, playerId),
      this
    );
    EventBus.on(
      "show-rotate-right",
      (playerId: number) =>
        this.gameMap.rotatePlayer(Direction.RIGHT, playerId),
      this
    );
    EventBus.on(
      "show-rotate-down",
      (playerId: number) => this.gameMap.rotatePlayer(Direction.DOWN, playerId),
      this
    );
    EventBus.on(
      "show-new-chunk",
      (chunkMap: ChunkDto) => this.gameMap.refreshMap(chunkMap),
      this
    );
    EventBus.on(
      "show-player-new-spawn",
      (playerNewSpawnDeserialized: CreatureWithPosDto) =>
        this.gameMap.spawnNewPlayer(playerNewSpawnDeserialized),
      this
    );
    EventBus.on(
      "show-remove-player",
      (playerId: number) => this.gameMap.destroyPlayerById(playerId),
      this
    );
    EventBus.on(
      "show-new-visible-player",
      (player: CreatureWithPosDto) => this.gameMap.addNewVisiblePlayer(player),
      this
    );
    EventBus.on(
      "show-sound",
      (sound: SoundDto) => this.gameMap.showSound(sound),
      this
    );
    EventBus.on("show-target-loss", () => this.gameMap.targetLoss(), this);
    EventBus.on("show-clear-target", () => this.gameMap.clearTarget(), this);
    EventBus.on(
      "show-new-target",
      (targetId: number) => this.gameMap.setNewTarget(targetId),
      this
    );
    EventBus.on(
      "show-player-dead",
      (dead: DeadDto) => this.deadPlayer(dead),
      this
    );
    EventBus.on(
      "show-take-old-position",
      (takeOldPosition: PlayerIdAndPos) =>
        this.gameMap.takeOldPosition(takeOldPosition),
      this
    );

    EventBus.on(
      "show-remove-item",
      (itemId: number) => this.gameMap.removeItem(itemId),
      this
    );
    EventBus.on(
      "show-add-item",
      (addItemDto: AddItemDto) => this.gameMap.addItem(addItemDto),
      this
    );
    EventBus.on(
      "show-new-exp",
      (newExp: NewExpDto) => this.gameMap.showNewExp(newExp),
      this
    );
    EventBus.on(
      "show-new-health",
      (newHealth: NewHealthDto) => this.gameMap.showNewHealth(newHealth),
      this
    );

    EventBus.emit("listeners-ready");
  }

  private defineAndStartTileAnims() {
    this.anims.create({
      key: "water",
      frames: this.anims.generateFrameNumbers("water"),
      frameRate: 2,
      repeat: -1,
    });
  }

  private defineHumanAnims() {
    this.creaturesSpritesConfig
      .getAllSprites()
      .map((it) => it.key)
      .forEach((key) => {
        this.anims.create({
          key: `${key}_walkDown`,
          frames: this.anims.generateFrameNumbers(key, {
            start: 1,
            end: 2,
          }),
          frameRate: 5,
          repeat: -1,
        });

        this.anims.create({
          key: `${key}_walkUp`,
          frames: this.anims.generateFrameNumbers(key, {
            start: 4,
            end: 5,
          }),
          frameRate: 5,
          repeat: -1,
        });

        this.anims.create({
          key: `${key}_walkLeft`,
          frames: this.anims.generateFrameNumbers(key, {
            start: 7,
            end: 8,
          }),
          frameRate: 5,
          repeat: -1,
        });

        this.anims.create({
          key: `${key}_walkRight`,
          frames: this.anims.generateFrameNumbers(key, {
            start: 10,
            end: 11,
          }),
          frameRate: 5,
          repeat: -1,
        });
      });
  }

  public update(time: number, delta: number): void {
    this.gameMap.updateWalkOtherPlayers(time, delta);

    if (this.gameMap.isCurrentPlayerDead()) {
      return;
    }

    if (this.cursors?.shift.isDown) {
      if (time - this.lastActionReadRotateTime > 100) {
        if (
          this.cursors?.down.isDown &&
          this.gameMap.getStandCurrentPlayerDirection() !== Direction.DOWN
        ) {
          EventBus.emit(walks.get(Direction.DOWN)!.rotateEventName);
          this.lastActionReadRotateTime = time;
        } else if (
          this.cursors?.up.isDown &&
          this.gameMap.getStandCurrentPlayerDirection() !== Direction.UP
        ) {
          EventBus.emit(walks.get(Direction.UP)!.rotateEventName);
          this.lastActionReadRotateTime = time;
        } else if (
          this.cursors?.left.isDown &&
          this.gameMap.getStandCurrentPlayerDirection() !== Direction.LEFT
        ) {
          EventBus.emit(walks.get(Direction.LEFT)!.rotateEventName);
          this.lastActionReadRotateTime = time;
        } else if (
          this.cursors?.right.isDown &&
          this.gameMap.getStandCurrentPlayerDirection() !== Direction.RIGHT
        ) {
          EventBus.emit(walks.get(Direction.RIGHT)!.rotateEventName);
          this.lastActionReadRotateTime = time;
        }
      }
    } else if (time - this.lastActionReadWalkTime > 200) {
      if (this.cursors?.down.isDown) {
        EventBus.emit(walks.get(Direction.DOWN)!.walkEventName);
        this.lastActionReadWalkTime = time;
      } else if (this.cursors?.up.isDown) {
        EventBus.emit(walks.get(Direction.UP)!.walkEventName);
        this.lastActionReadWalkTime = time;
      } else if (this.cursors?.left.isDown) {
        EventBus.emit(walks.get(Direction.LEFT)!.walkEventName);
        this.lastActionReadWalkTime = time;
      } else if (this.cursors?.right.isDown) {
        EventBus.emit(walks.get(Direction.RIGHT)!.walkEventName);
        this.lastActionReadWalkTime = time;
      }
    }

    this.gameMap.updateWalkCurrentPlayer(time, delta);
  }

  private handlePointerDown(pointer: Phaser.Input.Pointer) {
    if (this.gameMap.isCurrentPlayerDead()) {
      this.lastMouseDownPos = null;
      EventBus.emit("last-mouse-global-pos-in-game-chanded", null);
      return;
    }

    if (pointer.leftButtonDown()) {
      this.lastMouseDownPos = this.gameMap.toGlobalPos(
        LocalScreenPosition.fromMousePos({
          x: pointer.x,
          y: pointer.y,
        })
      );
      EventBus.emit(
        "last-mouse-global-pos-in-game-chanded",
        this.lastMouseDownPos
      );
      return;
    }

    if (pointer.rightButtonDown()) {
      const localScreenMouseClickPos =
        LocalScreenPosition.fromMousePos(pointer);
      this.gameMap.doUseAction(localScreenMouseClickPos);
    }
  }

  private handlePointerMove(pointer: Phaser.Input.Pointer) {
    if (this.lastMouseDownPos && pointer.leftButtonDown()) {
      const currentGlobalPos = this.gameMap.toGlobalPos(
        LocalScreenPosition.fromMousePos({
          x: pointer.x,
          y: pointer.y,
        })
      );

      if (this.lastMouseDownPos.equals(currentGlobalPos)) {
        this.gameDiv?.classList.remove("cursor-move");
        this.gameDiv?.classList.add("cursor-crosshair");
      } else {
        this.gameDiv?.classList.remove("cursor-crosshair");
        this.gameDiv?.classList.add("cursor-move");
      }
    } else {
      this.lastMouseDownPos = null;
      this.gameDiv?.classList.remove("cursor-move");
      this.gameDiv?.classList.add("cursor-crosshair");
    }
  }

  private handlePointerUp(pointer: Phaser.Input.Pointer) {
    this.gameDiv!.classList.remove("cursor-move");
    this.gameDiv!.classList.add("cursor-crosshair");

    if (this.lastMouseDownPos && pointer.leftButtonReleased()) {
      const endGlobalPos = this.gameMap.toGlobalPos(
        LocalScreenPosition.fromMousePos({
          x: pointer.x,
          y: pointer.y,
        })
      );

      if (this.lastMouseDownPos.equals(endGlobalPos)) {
        this.gameMap.doWalkAction(this.lastMouseDownPos);
      } else {
        this.gameMap.moveItem(this.lastMouseDownPos, endGlobalPos);
      }
    }

    this.lastMouseDownPos = null;
    EventBus.emit("last-mouse-global-pos-in-game-chanded", null);
  }

  private deadPlayer(dead: DeadDto) {
    if (dead.deadPlayerId === this.initData.currentPlayer.id) {
      this.scene.stop();
    } else {
      this.gameMap.deadOtherPlayer(dead);
    }
  }

  private calculateDistance = (v1: Vector, v2: Vector): number => {
    const dx = v2.x - v1.x;
    const dy = v2.y - v1.y;
    return Math.sqrt(dx * dx + dy * dy);
  };
}
