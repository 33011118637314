import React from "react";

interface HealthBarProps {
  currentHp: number;
  allHp: number;
  remainingExp: BigInt;
  nextLevelExp: BigInt;
}

const HealthBar: React.FC<HealthBarProps> = ({
  currentHp,
  allHp,
  remainingExp,
  nextLevelExp,
}) => {
  const healthPercentage = (currentHp / allHp) * 100;

  const expPercentage = Number(
    (Number(nextLevelExp.valueOf() - remainingExp.valueOf()) * 100) /
      Number(nextLevelExp.valueOf())
  );

  return (
    <div className="mt-2">
      <div className="text-xs text-white mb-1">Current health</div>
      <div className="w-full bg-gray-800 rounded-lg overflow-hidden h-4 relative mb-4">
        <div
          className="bg-gradient-to-r from-red-700 via-red-500 to-red-700 h-full transition-width duration-300 ease-in-out"
          style={{ width: `${healthPercentage}%` }}
        ></div>
        <div className="absolute inset-0 flex justify-center items-center text-xs text-white font-bold">
          {currentHp} / {allHp}
        </div>
      </div>
      <div className="text-xs text-white mb-1">Remaining exp</div>
      <div className="w-full bg-gray-800 rounded-lg overflow-hidden h-4 relative">
        <div
          className="bg-gradient-to-r from-purple-700 via-purple-500 to-purple-700 h-full transition-width duration-300 ease-in-out"
          style={{ width: `${expPercentage}%` }}
        ></div>
        <div className="absolute inset-0 flex justify-center items-center text-xs text-white font-bold">
          {String(nextLevelExp.valueOf() - remainingExp.valueOf())} /{" "}
          {String(nextLevelExp.valueOf())}
        </div>
      </div>
    </div>
  );
};

export default HealthBar;
