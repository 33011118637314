import { MainScene } from "../MainScene";
import { TILE_SIZE } from "../GameConfig";
import { Vector } from "../models/position-models";

export class Item {
  private id: number;
  private sprite: Phaser.GameObjects.Sprite;

  constructor(scene: MainScene, pos: Vector, id: number, spriteKey: string) {
    this.id = id;
    this.sprite = scene.add
      .sprite(pos.x, pos.y, spriteKey)
      .setDisplaySize(TILE_SIZE, TILE_SIZE)
      .setDepth(1); // todo must be set from constructor and later updated
  }

  destroy() {
    this.sprite.destroy(true);
  }

  getX() {
    return this.sprite.x;
  }

  getY() {
    return this.sprite.y;
  }

  getId() {
    return this.id;
  }

  getPixelPosition(): Vector {
    return {
      x: this.getX(),
      y: this.getY(),
    };
  }

  getSprite() {
    return this.sprite;
  }
}
