import { SoundDto } from "../game/models/deserialized-models";
import { CommandDeserializer } from "./CommandDeserializer";

export const deserializeShowSound = (data: Uint8Array): SoundDto => {
  const deserializer = new CommandDeserializer(data);
  return {
    creatureId: deserializer.getUint16(),
    sound: deserializer.getShortText(),
  };
};
