import { Vector } from "matter";
import { Creature } from "./Creature";
import { Tile } from "./Tile";
import { Item } from "./Item";
import { Obstacle } from "./Obstacle";

export class Field {
  private readonly tile: Tile;
  private readonly obstacle: Obstacle | null;

  private readonly items: Map<number, Item>;
  private readonly creatures: Map<number, Creature>;

  constructor(
    tile: Tile,
    obstacle: Obstacle | null,
    items: Item[],
    otherPlayers: Creature[]
  ) {
    this.tile = tile;
    this.obstacle = obstacle;

    this.items = new Map();
    items.forEach((i) => this.items.set(i.getId(), i));

    this.creatures = new Map();
    otherPlayers.forEach((p) => this.creatures.set(p.getId(), p));
  }

  addCreature(creature: Creature) {
    this.creatures.set(creature.getId(), creature);
  }

  removeCreature(creatureId: number): Creature | null {
    const removedPlayer = this.creatures.get(creatureId);
    this.creatures.delete(creatureId);
    return removedPlayer || null;
  }

  addItem(item: Item) {
    this.items.set(item.getId(), item); // todo setDepth
  }

  removeItem(itemId: number): Item | null {
    const removedItem = this.items.get(itemId);
    this.items.delete(itemId);
    return removedItem || null;
  }

  destroy() {
    this.tile.destroy();
    this.obstacle?.destroy();
    this.creatures.forEach((it) => it.destroy());
    this.items.forEach(it => it.destroy());
  }

  getCreatureById(creatureId: number): Creature | null {
    return this.creatures.get(creatureId) || null;
  }

  getItemById(itemId: number): Item | null {
    return this.items.get(itemId) || null;
  }

  getPixelPosition(): Vector {
    return this.tile.getPixelPosition();
  }

  getAllCreatures(): Creature[] {
    return Array.from(this.creatures.values());
  }
}
