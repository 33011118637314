import { useEffect, useRef, useState } from "react";
import { EventBus } from "../game/eventBus";
import { MessageDto } from "../game/models/deserialized-models";

interface ChatProps {
  currentPlayerId: number;
  messages: MessageDto[];
}

const Chat = (props: ChatProps) => {
  const [currentMessage, setCurrentMessage] = useState<string>("");
  const endOfMessagesRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleInputChange = (event: any) => {
    setCurrentMessage(event.target.value);
  };

  const handleSendMessage = (event: any) => {
    if (event.key === "Enter" && currentMessage.trim() !== "") {
      EventBus.emit("send-message", currentMessage.trim());
      setCurrentMessage("");
      event.preventDefault();
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [props.messages]);

  return (
    <div className="h-[110px] w-screen px-2 bg-lightbrown flex flex-col">
      <div className="overflow-y-scroll flex-grow bg-brown text-white px-2">
        {props.messages.map((message, index) => (
          <p
            className={`text-xs ${
              message.executorId === props.currentPlayerId
                ? "text-orange-500"
                : "text-white"
            }`}
            key={index}
          >
            {message.message}
          </p>
        ))}
        <div ref={endOfMessagesRef} />
      </div>
      <input
        type="text"
        value={currentMessage}
        onChange={handleInputChange}
        onKeyDown={handleSendMessage}
        className="h-[20px] mt-2 mb-2 text-xs bg-brown text-white"
        placeholder="Wpisz wiadomość..."
      />
    </div>
  );
};

export default Chat;
