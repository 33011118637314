import {
  FinishWalkDto,
  STAND_DIRECTION_MAP,
  StartWalkDto,
} from "../game/models/deserialized-models";
import { CommandDeserializer } from "./CommandDeserializer";
import { readGlobalPos } from "./helpers/backend-data-reader-helper";

export const deserializeStartWalk = (data: Uint8Array): StartWalkDto => {
  const deserializer = new CommandDeserializer(data);
  const playerId = deserializer.getUint16();
  const walkTimeInMillis = deserializer.getUint16();
  const globalPos = readGlobalPos(deserializer);

  return {
    playerId: playerId,
    walkTimeInMillis: walkTimeInMillis,
    currentPos: globalPos,
  };
};

export const deserializeFinishWalk = (data: Uint8Array): FinishWalkDto => {
  const deserializer = new CommandDeserializer(data);
  const playerId = deserializer.getUint16();
  const walkDir = STAND_DIRECTION_MAP.get(deserializer.getUint8());
  const globalPos = readGlobalPos(deserializer);

  return {
    playerId: playerId,
    standDirection: walkDir!,
    newPlayerGlobalPos: globalPos,
  };
};
