import { Vector } from "../game/models/position-models";
import { ServerCommandType } from "./server-command-types";

export const serializeRefreshMapCommand = (vector: Vector): ArrayBuffer => {
  const command = new ArrayBuffer(3);
  const view = new DataView(command);
  view.setUint8(0, ServerCommandType.RefreshMap);
  view.setInt8(1, vector.x);
  view.setInt8(2, vector.y);
  return command;
};
