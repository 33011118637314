import { useEffect, useRef } from "react";
import Phaser from "phaser";
import { SpriteConfig, SpriteConfigs } from "../game/sprites/SpriteConfig";
import { MapEditorScene } from "./MapEditorScene";

interface Props {
  spriteConfigs: SpriteConfigs;
}

const PhaserMapEditor = ({ spriteConfigs }: Props) => {
  const gameRef = useRef<Phaser.Game | null>(null);

  useEffect(() => {
    const config: Phaser.Types.Core.GameConfig = {
      type: Phaser.AUTO,
      parent: "menu-container",
      pixelArt: true,
      roundPixels: true,
      scale: {
        mode: Phaser.Scale.RESIZE,
        parent: "menu-container",
      },
    };

    if (gameRef.current === null) {
      gameRef.current = new Phaser.Game(config);
      gameRef.current.scene.add("MapEditorScene", MapEditorScene, true, {
        spriteConfigs: spriteConfigs,
      });
    }

    return () => {
      if (gameRef.current) {
        gameRef.current.destroy(true);
        gameRef.current = null;
      }
    };
  }, []);

  return (
    <div
      id="menu-container"
      className={`h-full lg:w-[1200px] 2xl:w-[1600px] bg-red-100`}
    ></div>
  );
};

export default PhaserMapEditor;
