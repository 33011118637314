import { errorCodes } from "./errorCodes";

type ApiOperation = () => Promise<Response>;

type OnSuccess<T> = (data: T) => void;
type OnError = (errorCode: string) => void;

export const invokeApi = async <T>(
  operation: ApiOperation,
  onSuccess: OnSuccess<T>,
  onError: OnError
): Promise<void> => {
  try {
    const response = await operation();
    const json = await response.json();

    if (response.ok) {
      return onSuccess(json as T);
    } else {
      return onError(json.error);
    }
  } catch (e) {
    console.error(e);
    return onError(errorCodes.UNKNOWN_ERROR);
  }
};
