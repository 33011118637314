import { NewHealthDto } from "../game/models/deserialized-models";
import { CommandDeserializer } from "./CommandDeserializer";

export const ddeserializeNewHealth = (data: Uint8Array): NewHealthDto => {
  const deserializer = new CommandDeserializer(data);

  return {
    creatureId: deserializer.getUint16(),
    currentHealth: deserializer.getUint16(),
    fullHealth: deserializer.getUint16(),
    difference: deserializer.getInt16(),
  };
};
