const ip = process.env.REACT_APP_SERVER_URL?.replace(
  "ws://",
  "http://"
).replace("wss://", "https://");

export const callRegisterUser = async (
  username: string,
  password: string,
  confirmedPassword: string
) => {
  const payload = {
    username,
    password,
    confirmedPassword,
  };

  return fetch(`${ip}/players`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};

export const callLoginUser = async (username: string, password: string) => {
  const payload = {
    username,
    password,
  };

  return fetch(`${ip}/players/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
