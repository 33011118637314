import { TILE_SIZE } from "../GameConfig";
import { MainScene } from "../MainScene";
import { getColorHex } from "../helpers/color-resolver";
import { Direction } from "../models/models";
import { Vector } from "../models/position-models";
import { Sprite } from "../sprites/SpriteConfig";
import { AbstractCreature } from "./AbstractCreature";

export class Creature extends AbstractCreature {
  private targetFrameSprite: Phaser.GameObjects.Graphics | null;

  constructor(
    scene: MainScene,
    pixelPos: Vector,
    name: string,
    standDirection: Direction,
    localY: number,
    id: number,
    allHp: number,
    currentHp: number,
    spriteData: Sprite
  ) {
    super(
      scene,
      pixelPos,
      name,
      standDirection,
      localY,
      id,
      allHp,
      currentHp,
      spriteData
    );
    this.targetFrameSprite = null;
  }

  setAsTarget() {
    const pixelPos = this.getPixelPos();
    this.targetFrameSprite = this.scene.add
      .graphics()
      .setPosition(pixelPos.x - TILE_SIZE / 2, pixelPos.y - TILE_SIZE / 2)
      .lineStyle(8, getColorHex(0.2), 0.5)
      .strokeRect(0, 0, TILE_SIZE, TILE_SIZE);
  }

  clearTarget() {
    this.targetFrameSprite?.destroy(true);
    this.targetFrameSprite = null;
  }

  destroy(): void {
    super.destroy();
    this.clearTarget();
  }

  protected moveByDs(ds: Vector): void {
    super.moveByDs(ds);
    this.targetFrameSprite?.setX(this.targetFrameSprite.x - ds.x);
    this.targetFrameSprite?.setY(this.targetFrameSprite.y - ds.y);
  }

  protected updatePos(pixelPos: Vector, localY: number) {
    super.updatePos(pixelPos, localY);
    this.targetFrameSprite?.setPosition(
      pixelPos.x - TILE_SIZE / 2,
      pixelPos.y - TILE_SIZE / 2
    );
  }
}
