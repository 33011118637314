import { MainScene } from "../MainScene";
import { Direction } from "../models/models";
import { Vector } from "../models/position-models";
import { Sprite } from "../sprites/SpriteConfig";
import { AbstractCreature } from "./AbstractCreature";

export class CurrentPlayer extends AbstractCreature {
  private dead: boolean;

  constructor(
    scene: MainScene,
    pixelPos: Vector,
    name: string,
    standDirection: Direction,
    localY: number,
    id: number,
    allHp: number,
    currentHp: number,
    spriteData: Sprite
  ) {
    super(
      scene,
      pixelPos,
      name,
      standDirection,
      localY,
      id,
      allHp,
      currentHp,
      spriteData
    );
    this.dead = false;

    scene.cameras.main.startFollow(
      this.characterSprite,
      false,
      1,
      1,
      0,
      spriteData.yOffset
    );
  }

  startWalk(
    walkTimeInMillis: number,
    walkDirection: Direction,
    anim: string,
    pixelPos: Vector,
    localY: number
  ) {
    if (this.dead) {
      return;
    }

    super.startWalk(walkTimeInMillis, walkDirection, anim, pixelPos, localY);
  }

  finishWalk(
    standDirection: Direction | null,
    pixelPos: Vector,
    localY: number
  ) {
    if (this.dead) {
      return;
    }

    super.finishWalk(standDirection, pixelPos, localY);
  }

  rotate(newStandDirection: Direction) {
    if (this.dead) {
      return;
    }

    super.rotate(newStandDirection);
  }

  destroy(): void {
    super.destroy();
    this.dead = true;
  }

  isDead(): boolean {
    return this.dead;
  }

  getPixelPos(): Vector {
    return {
      x: this.characterSprite.x,
      y: this.characterSprite.y - this.spriteData.yOffset,
    };
  }
}
