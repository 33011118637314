import { useEffect, useState } from "react";
import PhaserMapEditor from "../mapeditor/PhaserMapEditor";
import {
  MEPosition,
  MESaveData,
  MESaveMetadata,
  MapJSON,
  MEField,
} from "../mapeditor/mapEditorModels";
import { EventBus } from "../game/eventBus";
import { SpriteConfig, SpriteConfigs } from "../game/sprites/SpriteConfig";
import CollapsiblePanel from "../mapeditor/CollapsiblePanel";

const MapEditor = () => {
  const [spriteConfigs, setSpriteConfigs] = useState<SpriteConfigs | null>(
    null
  );
  const [currentPos, setCurrentPos] = useState<MEPosition>({ x: 0, y: 0 });
  const [respawnPos, setRespawnPos] = useState<MEPosition>({ x: 0, y: 0 });

  const [isTilePanelOpen, setIsTilePanelOpen] = useState(false);
  const [selectedTileId, setSelectedTileId] = useState<number | null>(null);

  const [isCreaturePanelOpen, setIsCreaturePanelOpen] = useState(false);
  const [selectedMonsterId, setSelectedMonsterId] = useState<number | null>(
    null
  );

  const [isObstaclePanelOpen, setObstaclePanelOpen] = useState(false);
  const [selectedObstacleId, setIsSelectedObstacleId] = useState<number | null>(
    null
  );

  const [editingField, setEditingField] = useState<MEField | null>(null);

  useEffect(() => {
    SpriteConfig.loadAll().then((it) => setSpriteConfigs(it));

    EventBus.on("me-new-pos", (position: MEPosition) =>
      setCurrentPos(position)
    );
    EventBus.on("me-save", (saveData: MESaveData) => exportMap(saveData));
    EventBus.on("me-edit-field", (field: MEField) => editField(field));
    EventBus.on("me-remove-edit-field", () => setEditingField(null));

    return () => {
      EventBus.off("me-new-pos");
      EventBus.off("me-save");
      EventBus.off("me-edit-field");
      EventBus.off("me-remove-edit-field");
    };
  }, []);

  useEffect(() => {
    EventBus.emit("me-selected-tile-id", selectedTileId);
  }, [selectedTileId]);

  useEffect(() => {
    EventBus.emit("me-selected-monster-id", selectedMonsterId);
  }, [selectedMonsterId]);

  useEffect(() => {
    EventBus.emit("me-selected-obstacle-id", selectedObstacleId);
  }, [selectedObstacleId]);

  const exportMap = (saveData: MESaveData) => {
    const finalMap = toFinalMap(saveData);

    const jsonData = JSON.stringify(finalMap, null, 2);
    const blob = new Blob([jsonData], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "map.json";
    a.click();
    URL.revokeObjectURL(url);
  };

  const toFinalMap = (saveData: MESaveData): MapJSON => ({
    playerSpawnPos: saveData.metadata.playerRespawnPos,
    map: saveData.map.flat().map((f) => f.representation()),
  });

  const handleTileSelect = (tileId: number | null) => {
    setSelectedTileId((prev) => (tileId === prev ? null : tileId));
    setSelectedMonsterId(null);
    setIsSelectedObstacleId(null);
    setEditingField(null);
  };

  const handleCreatureSelect = (creatureId: number | null) => {
    setSelectedMonsterId((prev) => (creatureId === prev ? null : creatureId));
    setSelectedTileId(null);
    setIsSelectedObstacleId(null);
    setEditingField(null);
  };

  const handleObstacleSelect = (obstacleId: number | null) => {
    setIsSelectedObstacleId((prev) =>
      obstacleId === prev ? null : obstacleId
    );
    setSelectedTileId(null);
    setSelectedMonsterId(null);
    setEditingField(null);
  };

  const handleTilePanelToggle = () => {
    setIsTilePanelOpen((prev) => !prev);
  };

  const handleMonsterPanelToggle = () => {
    setIsCreaturePanelOpen((prev) => !prev);
  };

  const handleObstaclePanelToggle = () => {
    setObstaclePanelOpen((prev) => !prev);
  };

  const handleXRespawnChange = (value: number) => {
    setRespawnPos((prev) => ({
      ...prev,
      x: value,
    }));
  };

  const handleYRespawnChange = (value: number) => {
    setRespawnPos((prev) => ({
      ...prev,
      y: value,
    }));
  };

  const handleClearSelection = () => {
    setSelectedTileId(null);
    setSelectedMonsterId(null);
    setIsSelectedObstacleId(null);
  };

  const editField = (field: MEField) => {
    setIsTilePanelOpen(false);
    setIsCreaturePanelOpen(false);
    setObstaclePanelOpen(false);
    setEditingField(field);
  };

  const handleRespawnTimeChange = (value: number) => {
    setEditingField((prev) => ({
      ...prev!,
      monster: {
        ...prev!.monster!,
        respawnTimeInSec: value,
      },
    }));
  };

  const handleSaveField = () => {
    EventBus.emit("me-update-field", editingField);
    setEditingField(null);
  };

  const handleClearMonster = () => {
    setEditingField((prev) => ({
      ...prev!,
      monster: null,
    }));
  };

  const handleLoadMap = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target?.result as string;
        const loadedMap = JSON.parse(text) as MapJSON;
        setRespawnPos(loadedMap.playerSpawnPos);
        EventBus.emit("me-load-map", loadedMap);
      };
      reader.readAsText(file);
    }
  };

  return spriteConfigs ? (
    <div className="flex flex-row w-screen h-screen max-lg:hidden">
      <PhaserMapEditor spriteConfigs={spriteConfigs} />
      <div className="flex flex-col text-white text-xs border-2 border-lightbrown bg-brown p-6 flex-1 gap-2 overflow-y-scroll">
        <p className="text-xs">{`Position x=${currentPos.x}, y=${currentPos.y}`}</p>
        <div className="flex flex-row gap-2 items-center">
          <label className="text-xs">Respawn X:</label>
          <input
            type="number"
            value={respawnPos.x}
            onChange={(e) => handleXRespawnChange(parseInt(e.target.value))}
            className="w-16 text-black p-1"
          />
          <label className="text-xs">Respawn Y:</label>
          <input
            type="number"
            value={respawnPos.y}
            onChange={(e) => handleYRespawnChange(parseInt(e.target.value))}
            className="w-16 text-black p-1"
          />
        </div>
        <button
          onClick={() =>
            EventBus.emit("me-execute-save", { playerRespawnPos: respawnPos })
          }
          className="mt-4 p-2 bg-green-500 hover:bg-green-700 text-white text-xs rounded"
        >
          Save Map
        </button>

        <button
          onClick={handleClearSelection}
          className="p-2 text-white text-xs rounded bg-blue-500 hover:bg-blue-800"
        >
          Clear selection
        </button>

        <button
          onClick={() => document.getElementById("loadMapInput")?.click()}
          className="p-2 bg-yellow-500 hover:bg-yellow-700 text-white text-xs rounded"
        >
          Load Map
        </button>
        <input
          id="loadMapInput"
          type="file"
          accept=".json"
          style={{ display: "none" }} // Ukrywasz, ale nadal dostępny do kliknięcia
          onChange={handleLoadMap}
        />

        {editingField && (
          <div className="bg-gray-700 p-4 mt-4 rounded border border-gray-600">
            <h3 className="text-white text-xs mb-2">{`x=${editingField.position.x} y=${editingField.position.y} editor`}</h3>
            <div className="flex flex-col gap-2">
              {editingField.monster && (
                <div className="flex flex-col gap-2 my-4 p-2 border-2">
                  <p className="text-xs font-bold text-white">
                    {spriteConfigs.creatures.getSpriteKey(
                      editingField.monster.typeId
                    )}
                  </p>
                  <label className="text-white text-xs">
                    Respawn Time (seconds):
                  </label>
                  <input
                    type="number"
                    value={editingField.monster.respawnTimeInSec}
                    onChange={(e) =>
                      handleRespawnTimeChange(parseInt(e.target.value))
                    }
                    className="w-full text-black p-2"
                  />
                  <button
                    onClick={handleClearMonster}
                    className="p-2 text-white text-xs rounded bg-red-500 hover:bg-red-700"
                  >
                    Clear Monsters
                  </button>
                </div>
              )}
              <button
                onClick={handleSaveField}
                className="p-2 text-white text-xs rounded bg-green-500 hover:bg-green-700"
              >
                Save Field
              </button>
            </div>
          </div>
        )}

        <CollapsiblePanel
          title="Tiles"
          isOpen={isTilePanelOpen}
          togglePanel={handleTilePanelToggle}
        >
          <div className="max-h-[500px] overflow-y-scroll bg-gray-800 p-2 rounded border border-gray-700">
            {spriteConfigs.tiles.getAllSprites().map((sprite) => (
              <div
                key={sprite.typeId}
                className={`flex items-center gap-2 p-2 cursor-pointer ${
                  selectedTileId === sprite.typeId
                    ? "bg-blue-500"
                    : "hover:bg-gray-700"
                }`}
                onClick={() => handleTileSelect(sprite.typeId)}
              >
                {sprite.anim ? (
                  <div
                    className="w-[128px] h-[128px] bg-no-repeat bg-cover"
                    style={{
                      backgroundImage: `url(${sprite.spriteSrc})`,
                      backgroundPosition: "0 0",
                      backgroundSize: "512px 128px", // Cały spritesheet w jednym rzędzie
                    }}
                  ></div>
                ) : (
                  <img
                    src={sprite.spriteSrc}
                    alt={sprite.key}
                    className="w-[128px] h-[128px]"
                  />
                )}
                <span className="text-white text-xs">{sprite.key}</span>
              </div>
            ))}
          </div>
        </CollapsiblePanel>

        <CollapsiblePanel
          title="Creatures"
          isOpen={isCreaturePanelOpen}
          togglePanel={handleMonsterPanelToggle}
        >
          <div className="max-h-[500px] overflow-y-scroll bg-gray-800 p-2 rounded border border-gray-700">
            {spriteConfigs.creatures
              .getAllSprites()
              .filter((sprite) => sprite.typeId !== 0x00)
              .map((sprite) => (
                <div
                  key={sprite.typeId}
                  className={`flex items-center gap-2 p-2 cursor-pointer ${
                    selectedMonsterId === sprite.typeId
                      ? "bg-blue-500"
                      : "hover:bg-gray-700"
                  }`}
                  onClick={() => handleCreatureSelect(sprite.typeId)}
                >
                  <div
                    className="w-[128px] h-[128px] bg-no-repeat bg-cover"
                    style={{
                      backgroundImage: `url(${sprite.spriteSrc})`,
                      backgroundPosition: "0 0",
                      backgroundSize: "1536px 128px", // Spritesheet dla creatures
                    }}
                  ></div>
                  <span className="text-white text-xs">{sprite.key}</span>
                </div>
              ))}
          </div>
        </CollapsiblePanel>

        <CollapsiblePanel
          title="Obstacles"
          isOpen={isObstaclePanelOpen}
          togglePanel={handleObstaclePanelToggle}
        >
          <div className="max-h-[500px] overflow-y-scroll bg-gray-800 p-2 rounded border border-gray-700">
            {spriteConfigs.obstacles.getAllSprites().map((sprite) => (
              <div
                key={sprite.typeId}
                className={`flex items-center gap-2 p-2 cursor-pointer ${
                  selectedObstacleId === sprite.typeId
                    ? "bg-blue-500"
                    : "hover:bg-gray-700"
                }`}
                onClick={() => handleObstacleSelect(sprite.typeId)}
              >
                <img
                  src={sprite.spriteSrc}
                  alt={sprite.key}
                  className="w-[128px] h-[128px]"
                />
                <span className="text-white text-xs">{sprite.key}</span>
              </div>
            ))}
          </div>
        </CollapsiblePanel>
      </div>
    </div>
  ) : (
    <div>Loading...</div>
  );
};

export default MapEditor;
