import { MainScene } from "./MainScene";
import { InitDataDto } from "./models/deserialized-models";
import {
  LocalPosition,
  LocalScreenPosition,
  Vector,
} from "./models/position-models";

export const TILE_SIZE = 128;

export class GameConfig {
  private readonly rows: number;
  private readonly cols: number;

  private readonly visibleRows: number;
  private readonly visibleCols: number;
  private readonly centerLocalScreenPos: LocalScreenPosition;

  private readonly offsetToRefreshMap: Vector;

  private readonly initCenterLocalPos: LocalPosition;

  private readonly centerPixelPos: Vector;
  private readonly initStartPixelPos: Vector;

  constructor(scene: MainScene, initData: InitDataDto) {
    this.cols = initData.numberOfCols;
    this.rows = initData.numberOfRows;

    this.visibleCols = initData.visibleCols;
    this.visibleRows = initData.visibleRows;

    this.centerLocalScreenPos = new LocalScreenPosition(
      (this.visibleCols - 1) / 2,
      (this.visibleRows - 1) / 2
    );

    this.offsetToRefreshMap = {
      x: initData.xOffsetToRefreshMap,
      y: initData.yOffsetToRefreshMap,
    };
    this.initCenterLocalPos = new LocalPosition(
      (this.cols - 1) / 2,
      (this.rows - 1) / 2
    );
    this.centerPixelPos = {
      x: scene.scale.width / 2,
      y: scene.scale.height / 2,
    };

    this.initStartPixelPos = {
      x:
        this.centerPixelPos.x - this.initCenterLocalPos.getLocalX() * TILE_SIZE,
      y:
        this.centerPixelPos.y - this.initCenterLocalPos.getLocalY() * TILE_SIZE,
    };
  }

  getRows() {
    return this.rows;
  }

  getCols() {
    return this.cols;
  }

  getVisibleRows() {
    return this.visibleRows;
  }

  getVisibleCols() {
    return this.visibleCols;
  }

  getCenterLocalScreenPos() {
    return this.centerLocalScreenPos;
  }

  getInitStartPixelPos(): Vector {
    return this.initStartPixelPos;
  }

  getCenterPixelPos(): Vector {
    return this.centerPixelPos;
  }

  getCenterLocalPos(): LocalPosition {
    return this.initCenterLocalPos;
  }

  getOffsetToRefreshMap(): Vector {
    return this.offsetToRefreshMap;
  }

  isOutside(localPos: LocalPosition): boolean {
    return (
      localPos.getLocalX() < 0 ||
      localPos.getLocalY() < 0 ||
      localPos.getLocalX() >= this.cols ||
      localPos.getLocalY() >= this.rows
    );
  }
}
