import {
  ChunkDto,
  ChunkRowDto,
  FieldDto,
  STAND_DIRECTION_MAP,
} from "../game/models/deserialized-models";
import { CommandDeserializer } from "./CommandDeserializer";
import { readField } from "./helpers/backend-data-reader-helper";

export const deserializeNewChunk = (data: Uint8Array): ChunkDto => {
  const deserializer = new CommandDeserializer(data);

  const offsetX = deserializer.getInt8();

  const offsetY = deserializer.getInt8();

  const chunkRows: ChunkRowDto[] = [];
  while (deserializer.getOffset() < deserializer.getByteLength()) {
    const currentRowIdx = deserializer.getUint8();

    const startIdx = deserializer.getUint8();

    const endIdx = deserializer.getUint8();

    const fields: FieldDto[] = [];
    if (endIdx >= startIdx) {
      for (let i = startIdx; i <= endIdx; ++i) {
        fields.push(readField(deserializer));
      }

      chunkRows.push({
        currentRowIdx: currentRowIdx,
        startIdx: startIdx,
        endIdx: endIdx,
        fields: fields,
      });
    }
  }

  return {
    offsetX: offsetX,
    offsetY: offsetY,
    chunkRows: chunkRows,
  };
};
