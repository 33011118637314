import { GlobalPosition } from "../../game/models/position-models";
import {
  CreatureDto,
  ItemDto,
  FieldDto,
  STAND_DIRECTION_MAP,
  PlayerStatsDto,
  ExpDto,
} from "../../game/models/deserialized-models";
import { CommandDeserializer } from "../CommandDeserializer";

export const readField = (deserializer: CommandDeserializer): FieldDto => {
  const tileId = deserializer.getUint8();

  const hasObstacle = Boolean(deserializer.getUint8());

  const obstacleId = hasObstacle ? deserializer.getUint8() : null;

  const itemsCount = deserializer.getUint8();

  const items = [];

  for (let i = 0; i < itemsCount; ++i) {
    items.push(readItem(deserializer));
  }

  const playersCount = deserializer.getUint8();

  const players = [];

  for (let i = 0; i < playersCount; ++i) {
    players.push(readCreature(deserializer));
  }

  return {
    tileId: tileId,
    obstacleId: obstacleId,
    items: items,
    creatures: players,
  };
};

export const readGlobalPos = (
  deserializer: CommandDeserializer
): GlobalPosition => {
  return new GlobalPosition(deserializer.getUint16(), deserializer.getUint16());
};

export const readCreature = (
  deserializer: CommandDeserializer
): CreatureDto => {
  return {
    id: deserializer.getUint16(),
    typeId: deserializer.getUint8(),
    standDirection: STAND_DIRECTION_MAP.get(deserializer.getUint8())!,
    name: deserializer.getShortText(),
    allHp: deserializer.getUint16(),
    currentHp: deserializer.getUint16(),
  };
};

export const readPlayerStats = (
  deserializer: CommandDeserializer
): PlayerStatsDto => {
  return {
    exp: readExp(deserializer),
    strength: deserializer.getUint16(),
    meleeWeaponUseTechnique: deserializer.getUint16(),
    shieldUsageTechnique: deserializer.getUint16(),
    reflex: deserializer.getUint16(),
    precision: deserializer.getUint16(),
    meleeAttackSpeed: deserializer.getUint16(),
    walkSpeed: deserializer.getUint16(),
    capacity: deserializer.getUint16(),
    loadPercent: deserializer.getInt8(),
  };
};

export const readExp = (deserializer: CommandDeserializer): ExpDto => {
  return {
    level: deserializer.getUint16(),
    currentExp: deserializer.getUint64(),
    currentExpToNextLvl: deserializer.getUint64(),
    allExpToNextLvl: deserializer.getUint64(),
    abilityPoints: deserializer.getUint16(),
  };
};

export const readItem = (deserializer: CommandDeserializer): ItemDto => {
  return {
    id: deserializer.getUint16(),
    typeId: deserializer.getUint8(),
  };
};
