import { ReactNode, useEffect, useRef, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { ClientCommandTypes } from "../deserializers/client-command-types";
import PhaserGame from "../game/PhaserGame";
import Chat from "./Chat";
import { EventBus } from "../game/eventBus";
import { deserializePlayerWithPos } from "../deserializers/player-spawn-deserializer";
import { deserializeRotate } from "../deserializers/rotate-deserializer";
import { serializePlayerId } from "../serializers/player-serializer";
import { deserializeOnlinePlayersAmount } from "../deserializers/online-players-amount-deserializer";
import { InitDataDto, MessageDto } from "../game/models/deserialized-models";
import { GlobalPosition, Vector } from "../game/models/position-models";
import { deserializeInitData } from "../deserializers/init-data-deserializer";
import { serializeMessage } from "../serializers/message-serializer";
import {
  serializeRotateDown,
  serializeRotateLeft,
  serializeRotateRight,
  serializeRotateUp,
  serializeWalkDown,
  serializeWalkLeft,
  serializeWalkRight,
  serializeWalkUp,
} from "../serializers/movement-serializer";
import { serializeRefreshMapCommand } from "../serializers/map-serializer";
import {
  deserializeGameCommunicate,
  deserializeMessage,
} from "../deserializers/message-deserializer";
import { deserializeNewChunk } from "../deserializers/new-chunk-deserializer";
import {
  serializeDoUseAction,
  serializeLongWalkAction,
} from "../serializers/do-action-serializer";
import {
  deserializeFinishWalk,
  deserializeStartWalk,
} from "../deserializers/walk-deserializer";
import { deserializeNewTarget } from "../deserializers/set-new-target-deserializer";
import YouAreDeadDialog from "../game/YouAreDeadDialog";
import { deserializePlayerIdAndPos } from "../deserializers/player-id-and-pos-deserializer";
import { deserializePlayerId } from "../deserializers/player-id-deserializer";
import { deserializeDead } from "../deserializers/dead-deserializer";
import { MoveItemDto } from "../game/models/serialized-models";
import { serializeMoveItem } from "../serializers/move-item-serializer";
import {
  deserializeAddItem,
  deserializeRemoveItem,
} from "../deserializers/move-item-deserializer";
import HealthBar from "./HealthBar";
import { deserializeNewExp } from "../deserializers/new-exp-deserializer";
import { ServerCommandType } from "../serializers/server-command-types";
import { serializeJustCommand } from "../serializers/simple-serializer";
import { deserializeUpgradedAbility } from "../deserializers/deserialize-upgraded-ability";
import { ddeserializeNewHealth } from "../deserializers/new-health-deserializer";
import { deserializeNewCapacity } from "../deserializers/new-capacity-deserializer";
import { deserializeShowSound } from "../deserializers/sound-deserializer";

interface GameProps {
  token: string;
}

const Game = (props: GameProps) => {
  const ip = process.env.REACT_APP_SERVER_URL;

  const isNotWss = !ip?.includes("wss://");

  const [messages, setMessages] = useState<MessageDto[]>([]);

  const [onlinePlayersAmount, setOnlinePlayersAmount] = useState(0);

  const [initData, setInitData] = useState<InitDataDto | null>(null);

  const [showDeadDialog, setShowDeadDialog] = useState<boolean>(false);

  const [areGameListenersReady, setGameListenersReady] =
    useState<boolean>(false);

  const messageBufferRef = useRef<(() => void)[]>([]);

  const [lastMouseGlobalPosInGame, setLastMouseGlobalPosInGame] =
    useState<GlobalPosition | null>(null);

  const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(
    `${ip}?token=${props.token}`,
    {
      onOpen: () => console.log("Connected with server!"),
      onClose: () => window.location.reload(),
      shouldReconnect: (closeEvent) => true,
    }
  );

  const handleMouseDown = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    field: string
  ) => {
    setLastMouseGlobalPosInGame(null);
  };

  const handleMouseUp = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    field: string
  ) => {
    if (lastMouseGlobalPosInGame) {
      console.log("MOVE TO PANEL!!");
    }
  };

  // useEffect(() => {
  //   let timer: any;
  //   if (gameCommunicate !== "") {
  //     timer = setTimeout(() => {
  //       setGameCommunicate("");
  //     }, 2000); // 2000 ms = 2 sekundy
  //   }
  //   return () => clearTimeout(timer); // Czyść timer przy unmount lub przy zmianie `m`
  // }, [gameCommunicate]);

  useEffect(() => {
    EventBus.on("listeners-ready", () => {
      messageBufferRef.current.forEach((action) => action());
      setGameListenersReady(true);
    });

    EventBus.on("send-message", (message: string) =>
      sendMessage(serializeMessage(message))
    );

    EventBus.on("walk-left", () => sendMessage(serializeWalkLeft), this);

    EventBus.on("walk-up", () => sendMessage(serializeWalkUp), this);

    EventBus.on("walk-right", () => sendMessage(serializeWalkRight), this);

    EventBus.on("walk-down", () => sendMessage(serializeWalkDown), this);

    EventBus.on("rotate-left", () => sendMessage(serializeRotateLeft), this);

    EventBus.on("rotate-up", () => sendMessage(serializeRotateUp), this);

    EventBus.on("rotate-right", () => sendMessage(serializeRotateRight), this);

    EventBus.on("rotate-down", () => sendMessage(serializeRotateDown), this);

    EventBus.on(
      "refresh-map",
      (v: Vector) => sendMessage(serializeRefreshMapCommand(v)),
      this
    );

    EventBus.on(
      "get-player",
      (playerId: number) => sendMessage(serializePlayerId(playerId)),
      this
    );

    EventBus.on(
      "do-use-action",
      (globalPos: GlobalPosition) =>
        sendMessage(serializeDoUseAction(globalPos)),
      this
    );

    EventBus.on(
      "do-long-walk-action",
      (globalPos: GlobalPosition) =>
        sendMessage(serializeLongWalkAction(globalPos)),
      this
    );

    EventBus.on(
      "move-item",
      (moveItem: MoveItemDto) => sendMessage(serializeMoveItem(moveItem)),
      this
    );

    EventBus.on("increase-ability", (commandType: number) =>
      sendMessage(serializeJustCommand(commandType))
    );

    EventBus.on(
      "last-mouse-global-pos-in-game-chanded",
      (lastMouseGlobalPosInGame: GlobalPosition | null) =>
        setLastMouseGlobalPosInGame(lastMouseGlobalPosInGame)
    );

    return () => {
      EventBus.off("listeners-ready");
      EventBus.off("send-message");
      EventBus.off("walk-left");
      EventBus.off("walk-up");
      EventBus.off("walk-right");
      EventBus.off("walk-down");
      EventBus.off("rotate-left");
      EventBus.off("rotate-up");
      EventBus.off("rotate-right");
      EventBus.off("rotate-down");
      EventBus.off("refresh-map");
      EventBus.off("get-player");
      EventBus.off("do-use-action");
      EventBus.off("do-long-walk-action");
      EventBus.off("move-item");
      EventBus.off("increase-ability");
      EventBus.off("last-mouse-global-pos-in-game-chanded");
    };
  }, []);

  useEffect(() => {
    if (lastMessage !== null) {
      lastMessage.data.arrayBuffer().then((buffer: ArrayBuffer) => {
        const data = new Uint8Array(buffer);
        const commandType = data[0];

        const commandData = data.slice(1);

        switch (commandType) {
          case ClientCommandTypes.ShowMessage:
            setMessages((prev) => [...prev, deserializeMessage(commandData)]);
            break;
          case ClientCommandTypes.ShowGameCommunicate:
            const communicate = deserializeGameCommunicate(commandData);
            break;
          case ClientCommandTypes.ShowMap:
            setInitData(deserializeInitData(commandData));
            break;
          case ClientCommandTypes.ShowStartWalkLeft:
            enqueueOrEmit(() =>
              EventBus.emit(
                "show-start-walk-left",
                deserializeStartWalk(commandData)
              )
            );
            break;
          case ClientCommandTypes.ShowStartWalkUp:
            enqueueOrEmit(() =>
              EventBus.emit(
                "show-start-walk-up",
                deserializeStartWalk(commandData)
              )
            );
            break;
          case ClientCommandTypes.ShowStartWalkRight:
            enqueueOrEmit(() =>
              EventBus.emit(
                "show-start-walk-right",
                deserializeStartWalk(commandData)
              )
            );
            break;
          case ClientCommandTypes.ShowStartWalkDown:
            enqueueOrEmit(() =>
              EventBus.emit(
                "show-start-walk-down",
                deserializeStartWalk(commandData)
              )
            );
            break;
          case ClientCommandTypes.ShowFinishWalk:
            enqueueOrEmit(() =>
              EventBus.emit(
                "show-finish-walk",
                deserializeFinishWalk(commandData)
              )
            );
            break;
          case ClientCommandTypes.ShowNewChunk:
            enqueueOrEmit(() =>
              EventBus.emit("show-new-chunk", deserializeNewChunk(commandData))
            );
            break;
          case ClientCommandTypes.ShowRotateLeft:
            enqueueOrEmit(() =>
              EventBus.emit("show-rotate-left", deserializeRotate(commandData))
            );
            break;
          case ClientCommandTypes.ShowRotateUp:
            enqueueOrEmit(() =>
              EventBus.emit("show-rotate-up", deserializeRotate(commandData))
            );
            break;
          case ClientCommandTypes.ShowRotateRight:
            enqueueOrEmit(() =>
              EventBus.emit("show-rotate-right", deserializeRotate(commandData))
            );
            break;
          case ClientCommandTypes.ShowRotateDown:
            enqueueOrEmit(() =>
              EventBus.emit("show-rotate-down", deserializeRotate(commandData))
            );
            break;
          case ClientCommandTypes.ShowSpawnNewPlayer:
            enqueueOrEmit(() =>
              EventBus.emit(
                "show-player-new-spawn",
                deserializePlayerWithPos(commandData)
              )
            );
            break;
          case ClientCommandTypes.ShowRemovePlayer:
            enqueueOrEmit(() =>
              EventBus.emit(
                "show-remove-player",
                deserializePlayerId(commandData)
              )
            );
            break;
          case ClientCommandTypes.ShowNewVisiblePlayer:
            enqueueOrEmit(() =>
              EventBus.emit(
                "show-new-visible-player",
                deserializePlayerWithPos(commandData)
              )
            );
            break;
          case ClientCommandTypes.ShowOnlinePlayers:
            setOnlinePlayersAmount(deserializeOnlinePlayersAmount(commandData));
            break;
          case ClientCommandTypes.ShowSound:
            enqueueOrEmit(() =>
              EventBus.emit("show-sound", deserializeShowSound(commandData))
            );
            break;
          case ClientCommandTypes.ShowTargetLoss:
            enqueueOrEmit(() => EventBus.emit("show-target-loss"));
            break;
          case ClientCommandTypes.ShowClearTarget:
            enqueueOrEmit(() => EventBus.emit("show-clear-target"));
            break;
          case ClientCommandTypes.ShowNewTarget:
            enqueueOrEmit(() =>
              EventBus.emit(
                "show-new-target",
                deserializeNewTarget(commandData)
              )
            );
            break;
          case ClientCommandTypes.ShowPlayerDead:
            const deadDto = deserializeDead(commandData);
            if (deadDto.deadPlayerId === initData!.currentPlayer.id) {
              setShowDeadDialog(true);
            }

            enqueueOrEmit(() => EventBus.emit("show-player-dead", deadDto));
            break;
          case ClientCommandTypes.ShowTakeOldPosition:
            enqueueOrEmit(() =>
              EventBus.emit(
                "show-take-old-position",
                deserializePlayerIdAndPos(commandData)
              )
            );
            break;
          case ClientCommandTypes.ShowRemoveItem:
            enqueueOrEmit(() =>
              EventBus.emit(
                "show-remove-item",
                deserializeRemoveItem(commandData)
              )
            );
            break;
          case ClientCommandTypes.ShowAddItem:
            enqueueOrEmit(() =>
              EventBus.emit("show-add-item", deserializeAddItem(commandData))
            );
            break;
          case ClientCommandTypes.ShowNewExp:
            const newExp = deserializeNewExp(commandData);
            if (newExp.attackerId === initData!.currentPlayer.id) {
              setInitData((prevData) =>
                prevData?.currentPlayerStats
                  ? {
                      ...prevData,
                      currentPlayerStats: {
                        ...prevData.currentPlayerStats,
                        exp: newExp.expDto,
                      },
                    }
                  : prevData
              );
            }

            enqueueOrEmit(() => EventBus.emit("show-new-exp", newExp));
            break;
          case ClientCommandTypes.ShowUpgradedHealth:
            const upgradedHealth = deserializeUpgradedAbility(commandData);

            setInitData((prevData) =>
              prevData?.currentPlayerStats
                ? {
                    ...prevData,
                    currentPlayerStats: {
                      ...prevData.currentPlayerStats,
                      exp: {
                        ...prevData.currentPlayerStats.exp,
                        abilityPoints: upgradedHealth.abilityPoints,
                      },
                    },
                  }
                : prevData
            );
            break;
          case ClientCommandTypes.ShowUpgradedStrength:
            const upgradedStrength = deserializeUpgradedAbility(commandData);
            setInitData((prevData) =>
              prevData?.currentPlayerStats
                ? {
                    ...prevData,
                    currentPlayerStats: {
                      ...prevData.currentPlayerStats,
                      strength: upgradedStrength.abilityValue,
                      exp: {
                        ...prevData.currentPlayerStats.exp,
                        abilityPoints: upgradedStrength.abilityPoints,
                      },
                    },
                  }
                : prevData
            );
            break;
          case ClientCommandTypes.ShowUpgradedMeleeAttackSpeed:
            const upgradedMeleeAttackSpeed =
              deserializeUpgradedAbility(commandData);
            setInitData((prevData) =>
              prevData?.currentPlayerStats
                ? {
                    ...prevData,
                    currentPlayerStats: {
                      ...prevData.currentPlayerStats,
                      meleeAttackSpeed: upgradedMeleeAttackSpeed.abilityValue,
                      exp: {
                        ...prevData.currentPlayerStats.exp,
                        abilityPoints: upgradedMeleeAttackSpeed.abilityPoints,
                      },
                    },
                  }
                : prevData
            );
            break;
          case ClientCommandTypes.ShowUpgradedMeleeTechnique:
            const upgradedMeleeAttackTechnique =
              deserializeUpgradedAbility(commandData);
            setInitData((prevData) =>
              prevData?.currentPlayerStats
                ? {
                    ...prevData,
                    currentPlayerStats: {
                      ...prevData.currentPlayerStats,
                      meleeWeaponUseTechnique:
                        upgradedMeleeAttackTechnique.abilityValue,
                      exp: {
                        ...prevData.currentPlayerStats.exp,
                        abilityPoints:
                          upgradedMeleeAttackTechnique.abilityPoints,
                      },
                    },
                  }
                : prevData
            );
            break;
          case ClientCommandTypes.ShowUpgradedPrecision:
            const upgradedPrecision = deserializeUpgradedAbility(commandData);
            setInitData((prevData) =>
              prevData?.currentPlayerStats
                ? {
                    ...prevData,
                    currentPlayerStats: {
                      ...prevData.currentPlayerStats,
                      precision: upgradedPrecision.abilityValue,
                      exp: {
                        ...prevData.currentPlayerStats.exp,
                        abilityPoints: upgradedPrecision.abilityPoints,
                      },
                    },
                  }
                : prevData
            );
            break;
          case ClientCommandTypes.ShowUpgradedReflex:
            const upgradedReflex = deserializeUpgradedAbility(commandData);
            setInitData((prevData) =>
              prevData?.currentPlayerStats
                ? {
                    ...prevData,
                    currentPlayerStats: {
                      ...prevData.currentPlayerStats,
                      reflex: upgradedReflex.abilityValue,
                      exp: {
                        ...prevData.currentPlayerStats.exp,
                        abilityPoints: upgradedReflex.abilityPoints,
                      },
                    },
                  }
                : prevData
            );
            break;
          case ClientCommandTypes.ShowUpgradedShieldTechnique:
            const upgradedShieldTechnique =
              deserializeUpgradedAbility(commandData);
            setInitData((prevData) =>
              prevData?.currentPlayerStats
                ? {
                    ...prevData,
                    currentPlayerStats: {
                      ...prevData.currentPlayerStats,
                      shieldUsageTechnique:
                        upgradedShieldTechnique.abilityValue,
                      exp: {
                        ...prevData.currentPlayerStats.exp,
                        abilityPoints: upgradedShieldTechnique.abilityPoints,
                      },
                    },
                  }
                : prevData
            );
            break;
          case ClientCommandTypes.ShowUpgradedWalkingSpeed:
            const walkingSpeed = deserializeUpgradedAbility(commandData);
            setInitData((prevData) =>
              prevData?.currentPlayerStats
                ? {
                    ...prevData,
                    currentPlayerStats: {
                      ...prevData.currentPlayerStats,
                      walkSpeed: walkingSpeed.abilityValue,
                      exp: {
                        ...prevData.currentPlayerStats.exp,
                        abilityPoints: walkingSpeed.abilityPoints,
                      },
                    },
                  }
                : prevData
            );
            break;
          case ClientCommandTypes.ShowNewHealth:
            const newHealth = ddeserializeNewHealth(commandData);

            if (newHealth.creatureId === initData?.currentPlayer.id) {
              setInitData((prevData) =>
                prevData?.currentPlayerStats
                  ? {
                      ...prevData,
                      currentPlayer: {
                        ...prevData.currentPlayer,
                        currentHp: newHealth.currentHealth,
                        allHp: newHealth.fullHealth,
                      },
                    }
                  : prevData
              );
            }

            enqueueOrEmit(() => EventBus.emit("show-new-health", newHealth));
            break;
          case ClientCommandTypes.ShowNewCapacity:
            const newCapacity = deserializeNewCapacity(commandData);

            setInitData((prevData) =>
              prevData?.currentPlayerStats
                ? {
                    ...prevData,
                    currentPlayerStats: {
                      ...prevData.currentPlayerStats,
                      capacity: newCapacity.capacity,
                      loadPercent: newCapacity.loadPercentage,
                    },
                  }
                : prevData
            );

            break;
        }
      });
    }
  }, [lastMessage]);

  const handleConfirmDead = () => {
    setShowDeadDialog(false);
    getWebSocket()?.close();
  };

  const enqueueOrEmit = (emitAction: () => void) => {
    if (areGameListenersReady) {
      emitAction();
    } else {
      messageBufferRef.current.push(emitAction);
    }
  };

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  const stats = initData?.currentPlayerStats;

  return initData ? (
    <div className="flex flex-col w-screen h-screen max-lg:hidden">
      <div
        className={`flex game-panel-h border-8 border-lightbrown select-none`}
      >
        <Panel l={true}>
          {isNotWss && <PanelP>{`Server IP: ${ip}`}</PanelP>}
          <PanelP>{`Players online: ${onlinePlayersAmount}`}</PanelP>
          <hr></hr>
          {stats && (
            <>
              <HealthBar
                allHp={initData.currentPlayer.allHp}
                currentHp={initData.currentPlayer.currentHp}
                nextLevelExp={stats.exp.allExpToNextLvl}
                remainingExp={stats.exp.currentExpToNextLvl}
              ></HealthBar>
              <StatRow>
                <PanelP>Level</PanelP>
                <PanelP>{stats.exp.level}</PanelP>
              </StatRow>
              <StatRow>
                <PanelP>Experience</PanelP>
                <PanelP>{stats.exp.currentExp.toString()}</PanelP>
              </StatRow>
              <StatRow>
                <PanelP active={stats.exp.abilityPoints > 0}>
                  Ability points
                </PanelP>
                <PanelP active={stats.exp.abilityPoints > 0}>
                  {stats.exp.abilityPoints}
                </PanelP>
              </StatRow>
              <StatRow>
                <PanelP>Full Health</PanelP>
                <Skill
                  commandType={ServerCommandType.IncreaseHealth}
                  skill={initData.currentPlayer.allHp}
                  btnText={"+25"}
                  abilityPoints={stats.exp.abilityPoints}
                />
              </StatRow>
              <StatRow>
                <PanelP>Strength</PanelP>
                <Skill
                  commandType={ServerCommandType.IncreaseStrength}
                  skill={stats.strength}
                  abilityPoints={stats.exp.abilityPoints}
                />
              </StatRow>
              <StatRow>
                <PanelP>Meele Technique</PanelP>
                <Skill
                  commandType={ServerCommandType.IncreaseMeleeTechnique}
                  skill={stats.meleeWeaponUseTechnique}
                  abilityPoints={stats.exp.abilityPoints}
                />
              </StatRow>
              <StatRow>
                <PanelP>Melee Attack Speed</PanelP>
                <Skill
                  commandType={ServerCommandType.IncreaseMeleeAttackSpeed}
                  skill={stats.meleeAttackSpeed}
                  abilityPoints={stats.exp.abilityPoints}
                />
              </StatRow>
              <StatRow>
                <PanelP>Shield Technique</PanelP>
                <Skill
                  commandType={ServerCommandType.IncreaseShieldTechnique}
                  skill={stats.shieldUsageTechnique}
                  abilityPoints={stats.exp.abilityPoints}
                />
              </StatRow>
              <StatRow>
                <PanelP>Reflex</PanelP>
                <Skill
                  commandType={ServerCommandType.IncreaseReflex}
                  skill={stats.reflex}
                  abilityPoints={stats.exp.abilityPoints}
                />
              </StatRow>
              <StatRow>
                <PanelP>Precision</PanelP>
                <Skill
                  commandType={ServerCommandType.IncreasePrecision}
                  skill={stats.precision}
                  abilityPoints={stats.exp.abilityPoints}
                />
              </StatRow>
              <StatRow>
                <PanelP>Walking speed</PanelP>
                <Skill
                  commandType={ServerCommandType.IncreaseWalkingSpeed}
                  skill={stats.walkSpeed}
                  abilityPoints={stats.exp.abilityPoints}
                />
              </StatRow>
              <StatRow>
                <PanelP>Capacity</PanelP>
                <PanelP>{stats.capacity}</PanelP>
              </StatRow>
              <StatRow>
                <PanelP>Load Percentage</PanelP>
                <PanelP>{stats.loadPercent}%</PanelP>
              </StatRow>
            </>
          )}
        </Panel>
        <PhaserGame initData={initData} />
        <Panel l={false}>
          <div
            onMouseDown={(event) => handleMouseDown(event, "armor")}
            onMouseUp={(event) => handleMouseUp(event, "armor")}
            className="w-[50px] h-[50px] bg-black"
          ></div>
        </Panel>
      </div>
      <Chat currentPlayerId={initData!.currentPlayer.id} messages={messages} />
      <YouAreDeadDialog isOpen={showDeadDialog} onClose={handleConfirmDead} />
    </div>
  ) : (
    <div></div>
  );
};

type PanelProps = {
  children: ReactNode;
  l: boolean;
};

const Panel = ({ children, l }: PanelProps) => (
  <div
    className={`flex flex-col text-white text-xs ${
      l ? "border-r-2" : "border-l-2 cursor-crosshair"
    } border-lightbrown bg-brown p-6 flex-1 gap-2`}
  >
    {children}
  </div>
);

type PanelPProps = {
  children: ReactNode;
  active?: boolean;
};

const PanelP = ({ children, active = false }: PanelPProps) => (
  <p
    className={`text-xs break-all ${active ? "text-yellow-400" : "text-white"}`}
  >
    {children}
  </p>
);

type StatRowProps = {
  children: ReactNode;
};

const StatRow = ({ children }: StatRowProps) => (
  <div className={`flex flex-row justify-between`}>{children}</div>
);

type SkillProps = {
  skill: number;
  btnText?: string;
  abilityPoints: number;
  commandType: number;
};

const Skill = ({ skill, btnText, abilityPoints, commandType }: SkillProps) => {
  const active = abilityPoints > 0;

  const handleClick = () => {
    if (active) {
      EventBus.emit("increase-ability", commandType);
    }
  };

  return (
    <div className="flex flex-row items-center justify-center gap-2">
      {active && (
        <button
          className="text-yellow-400 text-xs font-bold underline"
          onClick={handleClick}
        >
          {btnText || "+1"}
        </button>
      )}
      <PanelP>{skill}</PanelP>
    </div>
  );
};

export default Game;
