import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Game from "./components/Game";
import Welcome from "./components/Welcome";
import MapEditor from "./components/MapEditor";

const App = () => {
  const [token, setToken] = useState<string>("");

  useEffect(() => {
    require("webfontloader").load({
      google: {
        families: ["Press Start 2P"],
      },
      active: () => {},
    });
  }, []);

  return (
    <Router>
      <div className="lg:hidden flex items-center justify-around w-screen h-screen bg-opacity-90 bg-yellow-800 bg-evoloria bg-cover bg-center">
        <div className="flex flex-col items-center justify-center gap-10 bg-opacity-90 bg-yellow-800 p-8 rounded-lg shadow-lg text-center max-w-xs w-full">
          <img src="/logo/logo1.png" className="w-[150px] h-[150px]" />
          <h1 className="text-white font-bold text-3xl">
            Only desktop supported
          </h1>
          <p className="mt-4 text-xs text-white">
            version {process.env.REACT_APP_VERSION}
          </p>
        </div>
      </div>
      <Routes>
        <Route
          path="/"
          element={token ? <Game token={token} /> : <Welcome onSubmit={setToken} />}
        />
        <Route path="/mapeditor" element={<MapEditor />} />
      </Routes>
    </Router>
  );
};

export default App;
