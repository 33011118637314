interface JsonElement {
  typeId: string;
  key: string;
  fileName: string;
  anim?: string;
  yOffset?: number;
  xScale?: number;
  yScale?: number;
}

export interface Sprite {
  typeId: number;
  key: string;
  spriteSrc: string;
  anim: boolean;
  yOffset: number;
  xScale: number;
  yScale: number;
}

export interface SpriteConfigs {
  tiles: SpriteConfig;
  items: SpriteConfig;
  creatures: SpriteConfig;
  obstacles: SpriteConfig;
}

export class SpriteConfig {
  private readonly sprites: Map<number, Sprite>;

  constructor(sprites: Map<number, Sprite>) {
    this.sprites = sprites;
  }

  static async loadAll(): Promise<SpriteConfigs> {
    return Promise.all([
      SpriteConfig.load("sprites/tiles", "tiles.json"),
      SpriteConfig.load("sprites/items", "items.json"),
      SpriteConfig.load("sprites/creatures", "creatures.json"),
      SpriteConfig.load("sprites/obstacles", "obstacles.json"),
    ]).then(
      ([
        tilesSpritesConfig,
        itemsSpritesConfig,
        creaturesSpritesConfig,
        obstaclesSpritesConfig,
      ]) => {
        return {
          tiles: tilesSpritesConfig,
          items: itemsSpritesConfig,
          creatures: creaturesSpritesConfig,
          obstacles: obstaclesSpritesConfig,
        };
      }
    );
  }

  private static async load(
    dir: string,
    configFileName: string
  ): Promise<SpriteConfig> {
    const fileContent = await fetch(`${dir}/${configFileName}`);
    const json = await fileContent.json();

    const tilesSprites: Map<number, Sprite> = new Map();

    json.forEach((element: JsonElement) =>
      tilesSprites.set(Number(element.typeId), {
        typeId: Number(element.typeId),
        key: element.key,
        spriteSrc: `${dir}/${element.fileName}`,
        anim: Boolean(element.anim),
        yOffset: element.yOffset || 0,
        xScale: element.xScale || 1,
        yScale: element.yScale || 1,
      })
    );

    return new SpriteConfig(tilesSprites);
  }

  getSprite(typeId: number): Sprite {
    return this.sprites.get(typeId)!;
  }

  getSpriteKey(typeId: number): string {
    return this.sprites.get(typeId)!.key;
  }

  isSpriteAnimated(typeId: number): boolean {
    return this.sprites.get(typeId)!.anim;
  }

  getAllSprites(): Sprite[] {
    return Array.from(this.sprites.values());
  }
}
