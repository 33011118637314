export enum ServerCommandType {
  SendMessage = 0x00,
  WalkLeft = 0x01,
  WalkUp = 0x02,
  WalkRight = 0x03,
  WalkDown = 0x04,
  RefreshMap = 0x05,
  RotateLeft = 0x06,
  RotateUp = 0x07,
  RotateRight = 0x08,
  RotateDown = 0x09,
  GetPlayer = 0x0a,
  DoUseAction = 0x0b,
  MoveItem = 0x0c,
  IncreaseHealth = 0x0d,
  IncreaseStrength = 0x0e,
  IncreaseMeleeTechnique = 0x0f,
  IncreaseMeleeAttackSpeed = 0x10,
  IncreaseShieldTechnique = 0x11,
  IncreaseReflex = 0x12,
  IncreasePrecision = 0x13,
  IncreaseWalkingSpeed = 0x14,
  DoLongWalkAction = 0x15,
}
