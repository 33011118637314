import { ServerCommandType } from "./server-command-types";

export const serializeJustCommand = (
  commandType: ServerCommandType
): ArrayBuffer => {
  const command = new ArrayBuffer(1);
  const view = new DataView(command);
  view.setUint8(0, commandType);
  return command;
};
