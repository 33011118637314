import { useEffect, useRef } from "react";
import Phaser from "phaser";
import { MainScene } from "./MainScene";
import { TILE_SIZE } from "./GameConfig";
import { InitDataDto } from "./models/deserialized-models";
import { SpriteConfig } from "./sprites/SpriteConfig";

interface Props {
  initData: InitDataDto;
}

const PhaserGame = ({ initData }: Props) => {
  const gameRef = useRef<Phaser.Game | null>(null);

  useEffect(() => {
    const config: Phaser.Types.Core.GameConfig = {
      type: Phaser.AUTO,
      parent: "game-container",
      width: initData.visibleCols * TILE_SIZE,
      height: initData.visibleRows * TILE_SIZE,
      pixelArt: true,
      roundPixels: true,
      scale: {
        mode: Phaser.Scale.HEIGHT_CONTROLS_WIDTH,
        parent: "game-container",
      },
    };
    SpriteConfig.loadAll().then((spriteConfigs) => {
      if (gameRef.current === null) {
        gameRef.current = new Phaser.Game(config);
        gameRef.current.scene.add("MainScene", MainScene, true, {
          initData: initData,
          spriteConfigs: spriteConfigs,
        });
      }
    });

    return () => {
      if (gameRef.current) {
        gameRef.current.destroy(true);
        gameRef.current = null;
      }
    };
  }, []);

  return (
    <div
      id="game-container"
      className="flex items-center justify-center h-full cursor-crosshair"
    ></div>
  );
};

export default PhaserGame;
