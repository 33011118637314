import { PlayerIdAndPos } from "../game/models/deserialized-models";
import { CommandDeserializer } from "./CommandDeserializer";
import { readGlobalPos } from "./helpers/backend-data-reader-helper";

export const deserializePlayerIdAndPos = (
  data: Uint8Array
): PlayerIdAndPos => {
  const deserializer = new CommandDeserializer(data);
  return {
    playerId: deserializer.getUint16(),
    globalPosition: readGlobalPos(deserializer),
  };
};
