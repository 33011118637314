import { ServerCommandType } from "./server-command-types";
import { serializeJustCommand } from "./simple-serializer";

export const serializeWalkLeft: ArrayBuffer = serializeJustCommand(
  ServerCommandType.WalkLeft
);
export const serializeWalkUp: ArrayBuffer = serializeJustCommand(
  ServerCommandType.WalkUp
);
export const serializeWalkRight: ArrayBuffer = serializeJustCommand(
  ServerCommandType.WalkRight
);
export const serializeWalkDown: ArrayBuffer = serializeJustCommand(
  ServerCommandType.WalkDown
);

export const serializeRotateLeft: ArrayBuffer = serializeJustCommand(
  ServerCommandType.RotateLeft
);
export const serializeRotateUp: ArrayBuffer = serializeJustCommand(
  ServerCommandType.RotateUp
);
export const serializeRotateRight: ArrayBuffer = serializeJustCommand(
  ServerCommandType.RotateRight
);
export const serializeRotateDown: ArrayBuffer = serializeJustCommand(
  ServerCommandType.RotateDown
);
