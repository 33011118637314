import { Field } from "../objects/Field";
import { Item } from "../objects/Item";
import { Creature } from "../objects/Creature";
import { LocalPosition } from "./position-models";

export enum Direction {
  UP = "UP",
  DOWN = "DOWN",
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export const standDirectionMap = new Map<Direction, number>();
standDirectionMap.set(Direction.DOWN, 0);
standDirectionMap.set(Direction.UP, 3);
standDirectionMap.set(Direction.LEFT, 6);
standDirectionMap.set(Direction.RIGHT, 9);

interface Walk {
  xFactor: number;
  yFactor: number;
  walkEventName: string;
  rotateEventName: string;
}

export const walks = new Map<Direction, Walk>();
walks.set(Direction.DOWN, {
  xFactor: 0,
  yFactor: -1,
  walkEventName: "walk-down",
  rotateEventName: "rotate-down",
});
walks.set(Direction.UP, {
  xFactor: 0,
  yFactor: 1,
  walkEventName: "walk-up",
  rotateEventName: "rotate-up",
});
walks.set(Direction.LEFT, {
  xFactor: 1,
  yFactor: 0,
  walkEventName: "walk-left",
  rotateEventName: "rotate-left",
});
walks.set(Direction.RIGHT, {
  xFactor: -1,
  yFactor: 0,
  walkEventName: "walk-right",
  rotateEventName: "rotate-right",
});

export interface FieldOtherPlayerPos {
  field: Field;
  otherPlayer: Creature;
  localPos: LocalPosition;
}

export interface FieldItemPos {
  field: Field;
  item: Item;
  localPos: LocalPosition;
}
