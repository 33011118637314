import { Direction } from "./models";
import { GlobalPosition } from "./position-models";

export interface InitDataDto {
  map: FieldDto[][];
  currentPlayer: CreatureDto;
  currentPlayerStats: PlayerStatsDto;
  startGlobalPos: GlobalPosition;
  numberOfRows: number;
  numberOfCols: number;
  visibleRows: number;
  visibleCols: number;
  xOffsetToRefreshMap: number;
  yOffsetToRefreshMap: number;
}

export interface FieldDto {
  tileId: number;
  obstacleId: number | null;
  items: ItemDto[];
  creatures: CreatureDto[];
}

export interface CreatureDto {
  id: number;
  typeId: number;
  name: string;
  standDirection: Direction;
  allHp: number;
  currentHp: number;
}

export interface PlayerStatsDto {
  exp: ExpDto;
  strength: number;
  meleeWeaponUseTechnique: number;
  shieldUsageTechnique: number;
  reflex: number;
  precision: number;
  meleeAttackSpeed: number;
  walkSpeed: number;
  capacity: number;
  loadPercent: number;
}

export const STAND_DIRECTION_MAP = new Map<number, Direction>();
STAND_DIRECTION_MAP.set(0x00, Direction.LEFT);
STAND_DIRECTION_MAP.set(0x01, Direction.UP);
STAND_DIRECTION_MAP.set(0x02, Direction.RIGHT);
STAND_DIRECTION_MAP.set(0x03, Direction.DOWN);

export interface CreatureWithPosDto {
  globalPosition: GlobalPosition;
  creatureDto: CreatureDto;
}

export interface StartWalkDto {
  currentPos: GlobalPosition;
  walkTimeInMillis: number;
  playerId: number;
}

export interface FinishWalkDto {
  standDirection: Direction;
  newPlayerGlobalPos: GlobalPosition;
  playerId: number;
}

export interface ChunkDto {
  offsetX: number;
  offsetY: number;
  chunkRows: ChunkRowDto[];
}

export interface ChunkRowDto {
  currentRowIdx: number;
  startIdx: number;
  endIdx: number;
  fields: FieldDto[];
}

export interface MessageDto {
  executorId: number;
  message: string;
}

export interface PlayerIdAndPos {
  playerId: number;
  globalPosition: GlobalPosition;
}

export interface DeadDto {
  deadPlayerId: number;
  position: GlobalPosition;
  item: ItemDto;
}

export interface ItemDto {
  id: number;
  typeId: number;
}

export interface AddItemDto {
  position: GlobalPosition;
  item: ItemDto;
}

export interface ExpDto {
  level: number;
  currentExp: BigInt;
  currentExpToNextLvl: BigInt;
  allExpToNextLvl: BigInt;
  abilityPoints: number;
}

export interface NewExpDto {
  attackerId: number;
  gainedExp: BigInt;
  expDto: ExpDto;
}

export interface UpgradedSkillDto {
  abilityValue: number;
  abilityPoints: number;
}

export interface NewHealthDto {
  creatureId: number;
  currentHealth: number;
  fullHealth: number;
  difference: number;
}

export interface NewCapacityDto {
  capacity: number;
  loadPercentage: number;
}

export interface SoundDto {
  creatureId: number;
  sound: string;
}
