import { Scene } from "phaser";
import MapEditorField from "./MapEditorField";
import { SpriteConfigs } from "../game/sprites/SpriteConfig";
import { EventBus } from "../game/eventBus";
import { MapJSON, MEField, MESaveMetadata } from "./mapEditorModels";

const TILE_SIZE = 128;
const MAP_SIZE = 128;
const DEFAULT_TILE_TYPE_ID = 0x01;
const SCROLL_SPEED = 100; // Prędkość przesuwania kamery za pomocą przycisków

interface MapEditorSceneData {
  spriteConfigs: SpriteConfigs;
}

export class MapEditorScene extends Scene {
  private map: MapEditorField[][];
  private highlightedField: MapEditorField | null;
  private editingField: MapEditorField | null = null;

  private isDragging: boolean;

  private spriteConfigs!: SpriteConfigs;

  private selectedTileId: number | null = null;
  private selectedMonsterId: number | null = null;
  private selectedObstacleId: number | null = null;

  constructor() {
    super("MapEditorScene");
    this.map = [];
    this.highlightedField = null;
    this.isDragging = false;
  }

  init(data: MapEditorSceneData) {
    this.spriteConfigs = data.spriteConfigs;
  }

  preload() {
    this.spriteConfigs.tiles.getAllSprites().forEach((tileSprite) => {
      if (tileSprite.anim) {
        this.load.spritesheet(tileSprite.key, tileSprite.spriteSrc, {
          frameWidth: TILE_SIZE,
          frameHeight: TILE_SIZE,
        });
      } else {
        this.load.image(tileSprite.key, tileSprite.spriteSrc);
      }
    });

    this.spriteConfigs.creatures.getAllSprites().forEach((creatureSprite) =>
      this.load.spritesheet(creatureSprite.key, creatureSprite.spriteSrc, {
        frameWidth: TILE_SIZE,
        frameHeight: TILE_SIZE,
      })
    );

    this.spriteConfigs.obstacles.getAllSprites().forEach((obstacleSprite) => {
      this.load.image(obstacleSprite.key, obstacleSprite.spriteSrc);
    });
  }

  create(): void {
    this.registerListeners();

    for (let i = 0; i < MAP_SIZE; i++) {
      const dataRow: MapEditorField[] = [];
      for (let j = 0; j < MAP_SIZE; j++) {
        dataRow.push(
          new MapEditorField(
            i,
            j,
            this,
            DEFAULT_TILE_TYPE_ID,
            this.spriteConfigs
          )
        );
      }
      this.map.push(dataRow);
    }

    const mapWidth = MAP_SIZE * TILE_SIZE;
    const mapHeight = MAP_SIZE * TILE_SIZE;
    const centerX = mapWidth / 2;
    const centerY = mapHeight / 2;

    const camera = this.cameras.main;
    camera.scrollX = centerX - camera.width / 2;
    camera.scrollY = centerY - camera.height / 2;

    this.input.on("pointerdown", (pointer: Phaser.Input.Pointer) => {
      this.isDragging = true;

      const field = this.getFieldFromPointer(pointer);
      if (field) {
        if (
          this.selectedTileId == null &&
          this.selectedMonsterId == null &&
          this.selectedObstacleId == null
        ) {
          if (this.editingField && this.editingField == field) {
            this.removeEditingField();
            EventBus.emit("me-remove-edit-field");
          } else {
            this.editField(field);
            EventBus.emit("me-edit-field", field.representation());
          }
        } else {
          this.selectedTileId != null && field.setTile(this.selectedTileId);

          this.selectedMonsterId != null &&
            field.setMonster(this.selectedMonsterId);

          this.selectedObstacleId != null &&
            field.setObstacle(this.selectedObstacleId);

          this.highlightField(field);
        }
      }
    });

    this.input.on("pointermove", (pointer: Phaser.Input.Pointer) => {
      const field = this.getFieldFromPointer(pointer);
      if (field) {
        if (this.isDragging && pointer.isDown) {
          this.selectedTileId != null && field.setTile(this.selectedTileId);
          this.selectedMonsterId != null &&
            field.setMonster(this.selectedMonsterId);
          this.selectedObstacleId != null &&
            field.setObstacle(this.selectedObstacleId);
        }
        this.highlightField(field);
      }
    });

    this.input.on("pointerup", () => {
      this.isDragging = false;
    });

    this.input.keyboard!.on("keydown", (event: KeyboardEvent) => {
      this.handleCameraScroll(event.key);
    });

    this.input.on(
      "wheel",
      (
        pointer: Phaser.Input.Pointer,
        gameObjects: any,
        deltaX: number,
        deltaY: number,
        deltaZ: number
      ) => {
        const zoomFactor = 0.001;
        this.cameras.main.zoom -= deltaY * zoomFactor;

        this.cameras.main.zoom = Phaser.Math.Clamp(
          this.cameras.main.zoom,
          0.02,
          1
        );

        const zoom = this.cameras.main.zoom;

        let lineWidth: number;

        if (zoom > 0.75) {
          lineWidth = 2;
        } else if (zoom <= 0.75 && zoom > 0.5) {
          lineWidth = 3;
        } else if (zoom <= 0.5 && zoom > 0.25) {
          lineWidth = 5;
        } else if (zoom <= 0.25 && zoom > 0.1) {
          lineWidth = 10;
        } else {
          lineWidth = 0;
        }

        this.map.flat().forEach((field) => field.setLineWidth(lineWidth));
      }
    );
  }

  update(time: number, delta: number): void {}

  private getFieldFromPointer(
    pointer: Phaser.Input.Pointer
  ): MapEditorField | null {
    const i = Math.floor(pointer.worldX / TILE_SIZE);
    const j = Math.floor(pointer.worldY / TILE_SIZE);

    if (i >= 0 && i < MAP_SIZE && j >= 0 && j < MAP_SIZE) {
      return this.map[i][j];
    }

    return null;
  }

  private highlightField(field: MapEditorField) {
    if (this.highlightedField && this.highlightedField !== field) {
      this.highlightedField.removeHighlight();
    }

    this.selectedTileId != null && field.highlightTile(this.selectedTileId);

    this.selectedMonsterId != null &&
      field.highlightMonster(this.selectedMonsterId);

    this.selectedObstacleId != null &&
      field.highlightObstacle(this.selectedObstacleId);

    this.highlightedField = field;
    EventBus.emit("me-new-pos", field.getPosition());
  }

  private editField(field: MapEditorField) {
    if (this.editingField && this.editingField !== field) {
      this.editingField.removeEditing();
    }

    field.setAsEditing();

    this.editingField = field;
  }

  private removeEditingField() {
    if (this.editingField) {
      this.editingField.removeEditing();
      this.editingField = null;
    }
  }

  private handleCameraScroll(key: string) {
    const camera = this.cameras.main;

    const scrollSpeed = SCROLL_SPEED / camera.zoom;

    switch (key) {
      case "ArrowUp":
        camera.scrollY -= scrollSpeed;
        break;
      case "ArrowDown":
        camera.scrollY += scrollSpeed;
        break;
      case "ArrowLeft":
        camera.scrollX -= scrollSpeed;
        break;
      case "ArrowRight":
        camera.scrollX += scrollSpeed;
        break;
      default:
        break;
    }
  }

  private loadMap(loadedMap: MapJSON) {
    this.map.flat().forEach((field) => field.clear());

    const { playerSpawnPos, map } = loadedMap;
    this.cameras.main.scrollX = playerSpawnPos.x * TILE_SIZE;
    this.cameras.main.scrollY = playerSpawnPos.y * TILE_SIZE;

    map.forEach((fieldData) => {
      const field = this.map[fieldData.position.x][fieldData.position.y];

      field.setTile(fieldData.tileTypeId);

      if (fieldData.obstacleTypeId !== null) {
        field.setObstacle(fieldData.obstacleTypeId);
      }

      if (fieldData.monster) {
        field.setMonster(fieldData.monster.typeId);
        field.update(fieldData);
      }
    });
  }

  private registerListeners() {
    EventBus.on("me-execute-save", (metadata: MESaveMetadata) => {
      EventBus.emit("me-save", {
        metadata: metadata,
        map: this.map,
      });
    });

    EventBus.on("me-selected-tile-id", (tileId: number | null) => {
      this.removeEditingField();
      this.selectedTileId = tileId;

      if (tileId != null) {
        this.selectedMonsterId = null;
        this.selectedObstacleId = null;
      }
    });

    EventBus.on("me-selected-monster-id", (monsterId: number | null) => {
      this.removeEditingField();
      this.selectedMonsterId = monsterId;

      if (monsterId != null) {
        this.selectedTileId = null;
        this.selectedObstacleId = null;
      }
    });

    EventBus.on("me-selected-obstacle-id", (obstacleId: number | null) => {
      this.removeEditingField();
      this.selectedObstacleId = obstacleId;

      if (obstacleId != null) {
        this.selectedTileId = null;
        this.selectedMonsterId = null;
      }
    });

    EventBus.on("me-update-field", (field: MEField) => {
      this.editingField!.update(field);
      this.removeEditingField();
    });

    EventBus.on("me-load-map", (loadedMap: MapJSON) => {
      this.loadMap(loadedMap);
    });
  }
}
