import { MainScene } from "../MainScene";
import { TILE_SIZE } from "../GameConfig";
import { Vector } from "../models/position-models";
import { Sprite, SpriteConfig } from "../sprites/SpriteConfig";

export class Obstacle {
  private image: Phaser.GameObjects.Image;

  constructor(
    scene: MainScene,
    pos: Vector,
    spriteData: Sprite,
    globalY: number
  ) {
    this.image = this.image = scene.add
      .image(pos.x, pos.y + spriteData.yOffset, spriteData.key!)
      .setDisplaySize(
        TILE_SIZE * spriteData.xScale,
        TILE_SIZE * spriteData.yScale
      )
      .setDepth(globalY);
  }

  destroy() {
    this.image.destroy(true);
  }
}
