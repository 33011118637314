interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const YouAreDeadDialog = ({ isOpen, onClose }: Props) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center">
      <div className="flex flex-col flex-center items-center bg-white rounded-lg p-6 shadow-lg max-w-sm w-full">
        <p>You are dead :(</p>
        <button
          onClick={onClose}
          className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          Relog
        </button>
      </div>
    </div>
  );
};

export default YouAreDeadDialog;
