import { GameConfig, TILE_SIZE } from "../GameConfig";

export interface Vector {
  x: number;
  y: number;
}

export interface GameScreenPixelPosition {
  x: number;
  y: number;
}

export class LocalPosition {
  private localX: number;
  private localY: number;

  constructor(localX: number, localY: number) {
    this.localX = localX;
    this.localY = localY;
  }

  public static fromPixelPos(pixelPos: Vector): LocalPosition {
    const x = Math.floor(pixelPos.x / TILE_SIZE);
    const y = Math.floor(pixelPos.y / TILE_SIZE);

    return new LocalPosition(x, y);
  }

  plusLocalScreenPos(localScreenPos: LocalScreenPosition): LocalPosition {
    return new LocalPosition(
      this.localX + localScreenPos.getLocalScreenX(),
      this.localY + localScreenPos.getLocalScreenY()
    );
  }

  toGlobalPosition(startGlobalPos: GlobalPosition): GlobalPosition {
    return new GlobalPosition(
      this.localX + startGlobalPos.getGlobalX(),
      this.localY + startGlobalPos.getGlobalY()
    );
  }

  minus(other: LocalPosition): Vector {
    return {
      x: other.localX - this.localX,
      y: other.localY - this.localY,
    };
  }

  toPixelPos(startPixelPos: Vector): Vector {
    return {
      x: startPixelPos.x + this.localX * TILE_SIZE,
      y: startPixelPos.y + this.localY * TILE_SIZE,
    };
  }

  minusVector(v: Vector): LocalPosition {
    return new LocalPosition(this.localX - v.x, this.localY - v.y);
  }

  plusVector(v: Vector): LocalPosition {
    return new LocalPosition(this.localX + v.x, this.localY + v.y);
  }

  equals(other: LocalPosition) {
    return this.localX === other.localX && this.localY === other.localY;
  }

  getLocalX() {
    return this.localX;
  }

  getLocalY() {
    return this.localY;
  }
}

export class GlobalPosition {
  private globalX: number;
  private globalY: number;

  constructor(globalX: number, globalY: number) {
    this.globalX = globalX;
    this.globalY = globalY;
  }

  toLocalPosition(startGlobalPos: GlobalPosition): LocalPosition {
    return new LocalPosition(
      this.globalX - startGlobalPos.getGlobalX(),
      this.globalY - startGlobalPos.getGlobalY()
    );
  }

  withOffset(v: Vector): GlobalPosition {
    return new GlobalPosition(this.globalX + v.x, this.globalY + v.y);
  }

  equals(other: GlobalPosition) {
    return this.globalX === other.globalX && this.globalY === other.globalY;
  }

  getGlobalX() {
    return this.globalX;
  }

  getGlobalY() {
    return this.globalY;
  }
}

export class LocalScreenPosition {
  private localScreenX: number;
  private localScreenY: number;

  constructor(x: number, y: number) {
    this.localScreenX = x;
    this.localScreenY = y;
  }

  static fromMousePos(pixelPos: Vector): LocalScreenPosition {
    const x = Math.floor(pixelPos.x / TILE_SIZE);
    const y = Math.floor(pixelPos.y / TILE_SIZE);

    return new LocalScreenPosition(x, y);
  }

  minus(other: LocalScreenPosition): LocalScreenPosition {
    return new LocalScreenPosition(
      this.localScreenX - other.localScreenX,
      this.localScreenY - other.localScreenY
    );
  }

  equals(other: LocalScreenPosition): boolean {
    return (
      this.localScreenX === other.localScreenX &&
      this.localScreenY === other.localScreenY
    );
  }

  getLocalScreenX() {
    return this.localScreenX;
  }

  getLocalScreenY() {
    return this.localScreenY;
  }
}
