export enum ClientCommandTypes {
  ShowMessage = 0x00,
  ShowMap = 0x01,
  ShowStartWalkLeft = 0x02,
  ShowStartWalkUp = 0x03,
  ShowStartWalkRight = 0x04,
  ShowStartWalkDown = 0x05,
  ShowFinishWalk = 0x06,
  ShowTakeOldPosition = 0x07,
  ShowGameCommunicate = 0x08,
  ShowNewChunk = 0x09,
  ShowRotateLeft = 0x0a,
  ShowRotateUp = 0x0b,
  ShowRotateRight = 0x0c,
  ShowRotateDown = 0x0d,
  ShowSpawnNewPlayer = 0x0e,
  ShowRemovePlayer = 0x0f,
  ShowNewVisiblePlayer = 0x10,
  ShowOnlinePlayers = 0x11,
  ShowSound = 0x12,
  ShowTargetLoss = 0x13,
  ShowClearTarget = 0x14,
  ShowNewTarget = 0x15,
  ShowPlayerDead = 0x16,
  ShowRemoveItem = 0x17,
  ShowAddItem = 0x18,
  ShowNewExp = 0x19,
  ShowUpgradedHealth = 0x1a,
  ShowUpgradedStrength = 0x1b,
  ShowUpgradedMeleeTechnique = 0x1c,
  ShowUpgradedMeleeAttackSpeed = 0x1d,
  ShowUpgradedShieldTechnique = 0x1e,
  ShowUpgradedReflex = 0x1f,
  ShowUpgradedPrecision = 0x20,
  ShowUpgradedWalkingSpeed = 0x21,
  ShowNewHealth = 0x22,
  ShowNewCapacity = 0x23,
}
