import { MoveItemDto } from "../game/models/serialized-models";
import { ServerCommandType } from "./server-command-types";

export const serializeMoveItem = ({
  startPos,
  endPos,
}: MoveItemDto): ArrayBuffer => {
  const command = new ArrayBuffer(9);
  const view = new DataView(command);
  view.setUint8(0, ServerCommandType.MoveItem);
  view.setUint16(1, startPos.getGlobalX());
  view.setUint16(3, startPos.getGlobalY());
  view.setUint16(5, endPos.getGlobalX());
  view.setUint16(7, endPos.getGlobalY());
  return command;
};
