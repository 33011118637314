import { FieldDto, InitDataDto } from "../game/models/deserialized-models";
import { GlobalPosition } from "../game/models/position-models";
import { CommandDeserializer } from "./CommandDeserializer";
import {
  readCreature,
  readField,
  readPlayerStats,
} from "./helpers/backend-data-reader-helper";

export const deserializeInitData = (data: Uint8Array): InitDataDto => {
  const deserializer = new CommandDeserializer(data);

  const currentPlayer = readCreature(deserializer);

  const currentPlayerStats = readPlayerStats(deserializer);

  const mapStartX = deserializer.getUint16();

  const mapStartY = deserializer.getUint16();

  const visibleRows = deserializer.getUint8();

  const visibleCols = deserializer.getUint8();

  const xOffsetToRefreshMap = deserializer.getUint8();

  const yOffsetToRefreshMap = deserializer.getUint8();

  const numberOfRows = deserializer.getUint8();
  const numberOfColumns = deserializer.getUint8();

  const result: FieldDto[][] = [];

  for (let i = 0; i < numberOfRows; i++) {
    const row: FieldDto[] = [];
    for (let j = 0; j < numberOfColumns; j++) {
      row.push(readField(deserializer));
    }
    result.push(row);
  }

  return {
    map: result,
    currentPlayer: currentPlayer,
    currentPlayerStats: currentPlayerStats,
    startGlobalPos: new GlobalPosition(mapStartX, mapStartY),
    numberOfRows: numberOfRows,
    numberOfCols: numberOfColumns,
    visibleRows: visibleRows,
    visibleCols: visibleCols,
    xOffsetToRefreshMap: xOffsetToRefreshMap,
    yOffsetToRefreshMap: yOffsetToRefreshMap,
  };
};
