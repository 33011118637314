import { ReactNode } from "react";

type CollapsiblePanelProps = {
  title: string;
  isOpen: boolean;
  togglePanel: () => void;
  children: ReactNode;
};

const CollapsiblePanel = ({
  title,
  isOpen,
  togglePanel,
  children,
}: CollapsiblePanelProps) => (
  <div className="mt-4">
    <button
      onClick={togglePanel}
      className={`p-2 text-white text-xs rounded ${
        isOpen ? "bg-blue-500 hover:bg-blue-700" : "bg-gray-500 hover:bg-gray-700"
      }`}
    >
      {isOpen ? `Hide ${title}` : `Show ${title}`}
    </button>
    {isOpen && <div className="mt-2">{children}</div>}
  </div>
);

export default CollapsiblePanel;
