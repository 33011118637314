import { MainScene } from "../MainScene";
import { TILE_SIZE } from "../GameConfig";
import { Vector } from "../models/position-models";

export class Tile {
  private sprite: Phaser.GameObjects.Sprite;

  constructor(
    scene: MainScene,
    pos: Vector,
    spriteKey: string,
    isAnim: boolean
  ) {
    this.sprite = scene.add
      .sprite(pos.x, pos.y, spriteKey!)
      .setDisplaySize(TILE_SIZE, TILE_SIZE)
      .setDepth(0);

    if (isAnim) {
      this.sprite.anims.play(spriteKey, true);
    }
  }

  destroy() {
    this.sprite.destroy(true);
  }

  getX() {
    return this.sprite.x;
  }

  getY() {
    return this.sprite.y;
  }

  getPixelPosition(): Vector {
    return {
      x: this.getX(),
      y: this.getY(),
    };
  }

  getSprite() {
    return this.sprite;
  }
}
