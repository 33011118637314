import { GameConfig } from "../GameConfig";
import { MainScene } from "../MainScene";
import { Direction, FieldItemPos, FieldOtherPlayerPos } from "../models/models";
import { Creature } from "./Creature";
import { Tile } from "./Tile";
import { EventBus } from "../eventBus";
import { CurrentPlayer } from "./CurrentPlayer";
import {
  GlobalPosition,
  LocalPosition,
  LocalScreenPosition,
  Vector,
} from "../models/position-models";
import {
  ChunkDto,
  ChunkRowDto,
  FinishWalkDto,
  InitDataDto,
  CreatureWithPosDto,
  StartWalkDto,
  PlayerIdAndPos,
  DeadDto,
  AddItemDto,
  NewExpDto,
  NewHealthDto,
  SoundDto,
} from "../models/deserialized-models";
import { Field } from "./Field";
import { SpriteConfig } from "../sprites/SpriteConfig";
import { Item } from "./Item";
import { Obstacle } from "./Obstacle";

export class GameMap {
  private readonly gameConfig: GameConfig;
  private readonly scene: MainScene;
  private readonly tileSpritesConfig: SpriteConfig;
  private readonly itemsSpritesConfig: SpriteConfig;
  private readonly creaturesSpritesConfig: SpriteConfig;
  private readonly obstaclesSpritesConfig: SpriteConfig;

  private startGlobalPos: GlobalPosition;

  private currentPlayer!: CurrentPlayer;

  private matrix: Field[][];

  private isMapRefreshing: boolean;

  constructor(
    scene: MainScene,
    initData: InitDataDto,
    tileSpritesConfig: SpriteConfig,
    itemsSpritesConfig: SpriteConfig,
    creaturesSpritesConfig: SpriteConfig,
    obstaclesSpritesConfig: SpriteConfig
  ) {
    this.scene = scene;
    this.startGlobalPos = initData.startGlobalPos;
    this.gameConfig = new GameConfig(scene, initData);
    this.tileSpritesConfig = tileSpritesConfig;
    this.itemsSpritesConfig = itemsSpritesConfig;
    this.creaturesSpritesConfig = creaturesSpritesConfig;
    this.obstaclesSpritesConfig = obstaclesSpritesConfig;

    this.isMapRefreshing = false;
    this.matrix = [];

    const currentPlayerData = initData.currentPlayer;

    this.currentPlayer = new CurrentPlayer(
      scene,
      this.gameConfig.getCenterPixelPos(),
      currentPlayerData.name,
      currentPlayerData.standDirection,
      this.gameConfig
        .getCenterLocalPos()
        .toGlobalPosition(this.startGlobalPos)
        .getGlobalY(),
      currentPlayerData.id,
      currentPlayerData.allHp,
      currentPlayerData.currentHp,
      creaturesSpritesConfig.getSprite(0)
    );

    for (let row = 0; row < this.gameConfig.getRows(); row++) {
      this.matrix[row] = [];
      for (let col = 0; col < this.gameConfig.getCols(); col++) {
        const data = initData.map[row][col];

        const localPos = new LocalPosition(col, row);
        const globalPos = localPos.toGlobalPosition(this.startGlobalPos);

        const pixelPos = localPos.toPixelPos(this.getStartPixelPos());

        this.matrix[row][col] = new Field(
          new Tile(
            scene,
            pixelPos,
            tileSpritesConfig.getSpriteKey(data.tileId),
            tileSpritesConfig.isSpriteAnimated(data.tileId)
          ),
          data.obstacleId != null
            ? new Obstacle(
                scene,
                pixelPos,
                obstaclesSpritesConfig.getSprite(data.obstacleId),
                globalPos.getGlobalY()
              )
            : null,
          data.items.map(
            (item) =>
              new Item(
                scene,
                pixelPos,
                item.id,
                this.itemsSpritesConfig.getSpriteKey(item.typeId)
              )
          ),
          data.creatures.map(
            (otherPlayer) =>
              new Creature(
                scene,
                pixelPos,
                otherPlayer.name,
                otherPlayer.standDirection,
                globalPos.getGlobalY(),
                otherPlayer.id,
                otherPlayer.allHp,
                otherPlayer.currentHp,
                this.creaturesSpritesConfig.getSprite(otherPlayer.typeId)
              )
          )
        );
      }
    }
  }

  startWalk(
    startWalkData: StartWalkDto,
    anim: string,
    walkDirection: Direction
  ) {
    if (startWalkData.playerId === this.currentPlayer.getId()) {
      this.startWalkCurrentPlayer(startWalkData, anim, walkDirection);
    } else {
      this.startWalkOtherPlayer(startWalkData, anim, walkDirection);
    }
  }

  finishWalk(finishWalkData: FinishWalkDto) {
    if (finishWalkData.playerId === this.currentPlayer.getId()) {
      this.finishWalkCurrentPlayer(finishWalkData);
    } else {
      this.finishWalkOtherPlayer(finishWalkData);
    }
  }

  updateWalkOtherPlayers(time: number, delta: number) {
    this.findAllPlayers().forEach((character: Creature) =>
      character.updateWalk(time, delta)
    );
  }

  updateWalkCurrentPlayer(time: number, delta: number) {
    this.currentPlayer.updateWalk(time, delta);
  }

  rotatePlayer(newStandDirection: Direction, playerId: number) {
    if (playerId === this.currentPlayer.getId()) {
      this.currentPlayer.rotate(newStandDirection);
    } else {
      const field = this.findFieldAndOtherPlayerByOtherPlayerId(playerId);

      if (!field) {
        return;
      }

      field!.otherPlayer.rotate(newStandDirection);
    }
  }

  checkMapRefreshing(newPlayerGlobalPos: GlobalPosition) {
    const offsetPlayerFromCenter = this.gameConfig
      .getCenterLocalPos()
      .minus(newPlayerGlobalPos.toLocalPosition(this.startGlobalPos));

    const offsetToRefreshMap = this.gameConfig.getOffsetToRefreshMap();

    const shouldRefreshMapByX =
      Math.abs(offsetPlayerFromCenter.x) >= offsetToRefreshMap.x;
    const shouldRefreshMapByY =
      Math.abs(offsetPlayerFromCenter.y) >= offsetToRefreshMap.y;

    if (!this.isMapRefreshing && (shouldRefreshMapByX || shouldRefreshMapByY)) {
      this.isMapRefreshing = true;
      EventBus.emit("refresh-map", {
        x: offsetPlayerFromCenter.x,
        y: offsetPlayerFromCenter.y,
      });
    }
  }

  refreshMap(chunkMap: ChunkDto) {
    const offset: Vector = {
      x: chunkMap.offsetX,
      y: chunkMap.offsetY,
    };

    const chunks: ChunkRowDto[] = chunkMap.chunkRows;
    const newMatrix: Field[][] = [];

    for (let row = 0; row < this.gameConfig.getRows(); row++) {
      newMatrix[row] = [];
    }

    const currentStartPixelPos = this.getStartPixelPos();

    for (let row = 0; row < this.gameConfig.getRows(); row++) {
      for (let col = 0; col < this.gameConfig.getCols(); col++) {
        const localPos = new LocalPosition(col, row);
        const newLocalPos = localPos.minusVector(offset);
        if (this.gameConfig.isOutside(newLocalPos)) {
          this.destroyField(localPos);
        } else {
          newMatrix[newLocalPos.getLocalY()][newLocalPos.getLocalX()] =
            this.matrix[row][col]!;
        }
      }
    }

    for (let row = 0; row < this.gameConfig.getRows(); row++) {
      const chunkRow: ChunkRowDto | null =
        chunks.find((it) => it.currentRowIdx === row) || null;

      let startIdx = 0;
      for (let col = 0; col < this.gameConfig.getCols(); col++) {
        if (chunkRow && col >= chunkRow.startIdx && col <= chunkRow.endIdx) {
          const fieldView = chunkRow.fields[startIdx++];

          const localPos = new LocalPosition(col, row);
          const globalPos = localPos.toGlobalPosition(this.startGlobalPos);
          const pixelPos = localPos
            .plusVector(offset)
            .toPixelPos(currentStartPixelPos);

          newMatrix[row][col] = new Field(
            new Tile(
              this.scene,
              pixelPos,
              this.tileSpritesConfig.getSpriteKey(fieldView.tileId),
              this.tileSpritesConfig.isSpriteAnimated(fieldView.tileId)
            ),
            fieldView.obstacleId != null
              ? new Obstacle(
                  this.scene,
                  pixelPos,
                  this.obstaclesSpritesConfig.getSprite(fieldView.obstacleId),
                  globalPos.getGlobalY()
                )
              : null,

            fieldView.items.map(
              (item) =>
                new Item(
                  this.scene,
                  pixelPos,
                  item.id,
                  this.itemsSpritesConfig.getSpriteKey(item.typeId)
                )
            ),
            fieldView.creatures.map(
              (otherPlayer) =>
                new Creature(
                  this.scene,
                  pixelPos,
                  otherPlayer.name,
                  otherPlayer.standDirection,
                  globalPos.getGlobalY(),
                  otherPlayer.id,
                  otherPlayer.allHp,
                  otherPlayer.currentHp,
                  this.creaturesSpritesConfig.getSprite(otherPlayer.typeId)
                )
            )
          );
        }
      }
    }

    for (let row = 0; row < this.gameConfig.getRows(); row++) {
      for (let col = 0; col < this.gameConfig.getCols(); col++) {
        this.matrix[row][col] = newMatrix[row][col];
      }
    }

    this.startGlobalPos = this.startGlobalPos.withOffset(offset);
    this.isMapRefreshing = false;
  }

  spawnNewPlayer({
    globalPosition,
    creatureDto: creatureDto,
  }: CreatureWithPosDto) {
    const playerLocalPos = globalPosition.toLocalPosition(this.startGlobalPos);

    if (this.gameConfig.isOutside(playerLocalPos)) {
      return;
    }

    const pixelPox = playerLocalPos.toPixelPos(this.getStartPixelPos());

    this.matrix[playerLocalPos.getLocalY()][
      playerLocalPos.getLocalX()
    ].addCreature(
      new Creature(
        this.scene,
        pixelPox,
        creatureDto.name,
        creatureDto.standDirection,
        playerLocalPos.getLocalY(),
        creatureDto.id,
        creatureDto.allHp,
        creatureDto.currentHp,
        this.creaturesSpritesConfig.getSprite(creatureDto.typeId)
      )
    );
  }

  getStartPixelPos(): Vector {
    if (this.matrix != null && this.matrix[0][0] != null) {
      return this.matrix[0][0].getPixelPosition();
    }

    return this.gameConfig.getInitStartPixelPos();
  }

  getCenterPixelPos() {
    return this.gameConfig.getCenterPixelPos();
  }

  getStandCurrentPlayerDirection() {
    return this.currentPlayer.getStandDirection();
  }

  destroyPlayerById(playerId: number) {
    const fieldWithExtraData =
      this.findFieldAndOtherPlayerByOtherPlayerId(playerId);

    if (fieldWithExtraData) {
      fieldWithExtraData.field.removeCreature(playerId)!.destroy();
    }
  }

  addNewVisiblePlayer({
    creatureDto: creatureDto,
    globalPosition,
  }: CreatureWithPosDto) {
    const localPos = globalPosition.toLocalPosition(this.startGlobalPos);

    if (this.gameConfig.isOutside(localPos)) {
      return;
    }

    this.matrix[localPos.getLocalY()][localPos.getLocalX()].addCreature(
      new Creature(
        this.scene,
        localPos.toPixelPos(this.getStartPixelPos()),
        creatureDto.name,
        creatureDto.standDirection,
        localPos.getLocalY(),
        creatureDto.id,
        creatureDto.allHp,
        creatureDto.currentHp,
        this.creaturesSpritesConfig.getSprite(creatureDto.typeId)
      )
    );
  }

  doWalkAction(lastClickedGlobalPos: GlobalPosition) {
    EventBus.emit("do-long-walk-action", lastClickedGlobalPos);
  }

  doUseAction(localScreenMouseClickPos: LocalScreenPosition) {
    const clickedGlobalPos = this.toGlobalPos(localScreenMouseClickPos);
    EventBus.emit("do-use-action", clickedGlobalPos);
  }

  moveItem(startPos: GlobalPosition, endPos: GlobalPosition) {
    EventBus.emit("move-item", {
      startPos,
      endPos,
    });
  }

  showNewHealth(showNewHealth: NewHealthDto) {
    if (this.currentPlayer.getId() === showNewHealth.creatureId) {
      this.currentPlayer.applyHpChange(
        showNewHealth.difference,
        showNewHealth.fullHealth,
        showNewHealth.currentHealth
      );
    } else {
      const fieldWithExtraData = this.findFieldAndOtherPlayerByOtherPlayerId(
        showNewHealth.creatureId
      );

      if (!fieldWithExtraData) {
        this.askServerAboutPlayer(showNewHealth.creatureId);
        return;
      }

      fieldWithExtraData.otherPlayer.applyHpChange(
        showNewHealth.difference,
        showNewHealth.fullHealth,
        showNewHealth.currentHealth
      );
    }
  }

  showSound(sound: SoundDto) {
    if (this.currentPlayer.getId() === sound.creatureId) {
      this.currentPlayer.showSound(sound.sound);
    } else {
      const fieldWithExtraData = this.findFieldAndOtherPlayerByOtherPlayerId(
        sound.creatureId
      );

      if (!fieldWithExtraData) {
        this.askServerAboutPlayer(sound.creatureId);
        return;
      }

      fieldWithExtraData.otherPlayer.showSound(sound.sound);
    }
  }

  targetLoss() {
    this.findAllPlayers().forEach((it) => it.clearTarget());
  }
  clearTarget() {
    this.findAllPlayers().forEach((it) => it.clearTarget());
  }

  setNewTarget(targetId: number) {
    this.clearTarget();
    this.findFieldAndOtherPlayerByOtherPlayerId(
      targetId
    )?.otherPlayer.setAsTarget();
  }

  deadOtherPlayer(dead: DeadDto) {
    this.destroyPlayerById(dead.deadPlayerId);
    const corpse = dead.item;
    const localPos = dead.position.toLocalPosition(this.startGlobalPos);
    const pixelPos = localPos.toPixelPos(this.getStartPixelPos());
    const field = this.matrix[localPos.getLocalY()][localPos.getLocalX()];
    field.addItem(
      new Item(
        this.scene,
        pixelPos,
        corpse.id,
        this.itemsSpritesConfig.getSpriteKey(corpse.typeId)
      )
    );
  }

  takeOldPosition(takeOldPosition: PlayerIdAndPos) {
    if (takeOldPosition.playerId === this.currentPlayer.getId()) {
      this.takeOldPosCurrentPlayer(takeOldPosition.globalPosition);
    } else {
      this.takeOldPosOtherPlayer(takeOldPosition);
    }
  }

  isCurrentPlayerDead(): boolean {
    return this.currentPlayer.isDead();
  }

  removeItem(itemId: number) {
    this.findFieldAndItemById(itemId)?.field.removeItem(itemId)?.destroy();
  }

  addItem(addItemDto: AddItemDto) {
    const localPos = addItemDto.position.toLocalPosition(this.startGlobalPos);
    const pixelPos = localPos.toPixelPos(this.getStartPixelPos());
    const item = addItemDto.item;

    const field = this.matrix[localPos.getLocalY()][localPos.getLocalX()];
    field.addItem(
      new Item(
        this.scene,
        pixelPos,
        item.id,
        this.itemsSpritesConfig.getSpriteKey(item.typeId)
      )
    );
  }

  showNewExp(newExp: NewExpDto) {
    if (newExp.attackerId === this.currentPlayer.getId()) {
      this.showNewExpForCurrentPlayer(newExp.gainedExp);
    } else {
      this.showNewExpForOtherPlayer(newExp.attackerId, newExp.gainedExp);
    }
  }

  toGlobalPos(localScreenMouseClickPos: LocalScreenPosition): GlobalPosition {
    const currentPlayerPixelPos = this.currentPlayer.getPixelPos();
    const startPixelPos = this.getStartPixelPos();

    const offsetCurrentPlayerInPixelsFromStart = {
      x: currentPlayerPixelPos.x - startPixelPos.x,
      y: currentPlayerPixelPos.y - startPixelPos.y,
    };

    return LocalPosition.fromPixelPos(offsetCurrentPlayerInPixelsFromStart)
      .plusLocalScreenPos(
        localScreenMouseClickPos.minus(
          this.gameConfig.getCenterLocalScreenPos()
        )
      )
      .toGlobalPosition(this.startGlobalPos);
  }

  private startWalkOtherPlayer(
    startWalkData: StartWalkDto,
    anim: string,
    walkDirection: Direction
  ) {
    const fieldWithExtraData = this.findFieldAndOtherPlayerByOtherPlayerId(
      startWalkData.playerId
    );

    const currentLocalPos = startWalkData.currentPos.toLocalPosition(
      this.startGlobalPos
    );

    if (!fieldWithExtraData) {
      if (!this.gameConfig.isOutside(currentLocalPos)) {
        this.askServerAboutPlayer(startWalkData.playerId);
      }
      return;
    }

    const oldPos = fieldWithExtraData.localPos;

    this.matrix[oldPos.getLocalY()][oldPos.getLocalX()].removeCreature(
      startWalkData.playerId
    );

    if (this.gameConfig.isOutside(currentLocalPos)) {
      fieldWithExtraData.otherPlayer.destroy();
    } else {
      fieldWithExtraData.otherPlayer.startWalk(
        startWalkData.walkTimeInMillis,
        walkDirection,
        anim,
        currentLocalPos.toPixelPos(this.getStartPixelPos()),
        currentLocalPos.toGlobalPosition(this.startGlobalPos).getGlobalY()
      );

      this.matrix[currentLocalPos.getLocalY()][
        currentLocalPos.getLocalX()
      ].addCreature(fieldWithExtraData.otherPlayer);
    }
  }

  private startWalkCurrentPlayer(
    startWalkData: StartWalkDto,
    anim: string,
    walkDirection: Direction
  ) {
    const currentLocalPos = startWalkData.currentPos.toLocalPosition(
      this.startGlobalPos
    );

    this.currentPlayer.startWalk(
      startWalkData.walkTimeInMillis,
      walkDirection,
      anim,
      currentLocalPos.toPixelPos(this.getStartPixelPos()),
      currentLocalPos.toGlobalPosition(this.startGlobalPos).getGlobalY()
    );
    this.checkMapRefreshing(startWalkData.currentPos);
  }

  private finishWalkCurrentPlayer(finishWalkData: FinishWalkDto) {
    const newLocalPos = finishWalkData.newPlayerGlobalPos.toLocalPosition(
      this.startGlobalPos
    );

    this.currentPlayer.finishWalk(
      finishWalkData.standDirection,
      newLocalPos.toPixelPos(this.getStartPixelPos()),
      newLocalPos.toGlobalPosition(this.startGlobalPos).getGlobalY()
    );
  }

  private finishWalkOtherPlayer(finishWalkData: FinishWalkDto) {
    const { playerId, standDirection, newPlayerGlobalPos } = finishWalkData;

    const fieldWithExtraData =
      this.findFieldAndOtherPlayerByOtherPlayerId(playerId);

    const newLocalPos = newPlayerGlobalPos.toLocalPosition(this.startGlobalPos);

    if (!fieldWithExtraData) {
      if (!this.gameConfig.isOutside(newLocalPos)) {
        this.askServerAboutPlayer(playerId);
      }
      return;
    }

    const oldPos = fieldWithExtraData.localPos;

    this.matrix[oldPos.getLocalY()][oldPos.getLocalX()].removeCreature(
      finishWalkData.playerId
    );

    if (this.gameConfig.isOutside(newLocalPos)) {
      fieldWithExtraData.otherPlayer.destroy();
    } else {
      fieldWithExtraData.otherPlayer.finishWalk(
        standDirection,
        newLocalPos.toPixelPos(this.getStartPixelPos()),
        newLocalPos.toGlobalPosition(this.startGlobalPos).getGlobalY()
      );
      this.matrix[newLocalPos.getLocalY()][newLocalPos.getLocalX()].addCreature(
        fieldWithExtraData.otherPlayer
      );
    }
  }

  private takeOldPosCurrentPlayer(globalPos: GlobalPosition) {
    const localPos = globalPos.toLocalPosition(this.startGlobalPos);

    this.currentPlayer.finishWalk(
      null,
      localPos.toPixelPos(this.getStartPixelPos()),
      localPos.toGlobalPosition(this.startGlobalPos).getGlobalY()
    );
  }

  private takeOldPosOtherPlayer(takeOldPosition: PlayerIdAndPos) {
    const fieldWithExtraData = this.findFieldAndOtherPlayerByOtherPlayerId(
      takeOldPosition.playerId
    );

    const localPos = takeOldPosition.globalPosition.toLocalPosition(
      this.startGlobalPos
    );

    if (!fieldWithExtraData) {
      if (!this.gameConfig.isOutside(localPos)) {
        this.askServerAboutPlayer(takeOldPosition.playerId);
      }
      return;
    }

    fieldWithExtraData.otherPlayer.finishWalk(
      null,
      localPos.toPixelPos(this.getStartPixelPos()),
      localPos.toGlobalPosition(this.startGlobalPos).getGlobalY()
    );
  }

  private findFieldAndOtherPlayerByOtherPlayerId(
    playerId: number
  ): FieldOtherPlayerPos | null {
    for (let r = 0; r < this.gameConfig.getRows(); r++) {
      for (let c = 0; c < this.gameConfig.getCols(); c++) {
        const possibleOtherPlayer = this.matrix[r][c].getCreatureById(playerId);
        if (possibleOtherPlayer) {
          return {
            field: this.matrix[r][c],
            otherPlayer: possibleOtherPlayer,
            localPos: new LocalPosition(c, r),
          };
        }
      }
    }

    return null;
  }

  private findFieldAndItemById(playerId: number): FieldItemPos | null {
    for (let r = 0; r < this.gameConfig.getRows(); r++) {
      for (let c = 0; c < this.gameConfig.getCols(); c++) {
        const possibleItem = this.matrix[r][c].getItemById(playerId);
        if (possibleItem) {
          return {
            field: this.matrix[r][c],
            item: possibleItem,
            localPos: new LocalPosition(c, r),
          };
        }
      }
    }

    return null;
  }

  private destroyField(localPos: LocalPosition) {
    const field = this.matrix[localPos.getLocalY()][localPos.getLocalX()];
    if (field) {
      field.destroy();
      this.matrix[localPos.getLocalY()][localPos.getLocalX()] = null!;
    }
  }

  private findAllPlayers(): Creature[] {
    return this.matrix
      .flat()
      .map((it) => it.getAllCreatures())
      .flat();
  }

  private askServerAboutPlayer(playerId: number) {
    EventBus.emit("get-player", playerId);
  }

  private showNewExpForCurrentPlayer(gainedExp: BigInt) {
    this.currentPlayer.gainExp(gainedExp);
  }

  private showNewExpForOtherPlayer(attackerId: number, gainedExp: BigInt) {
    this.findFieldAndOtherPlayerByOtherPlayerId(
      attackerId
    )?.otherPlayer.gainExp(gainedExp);
  }
}
