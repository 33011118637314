import { UpgradedSkillDto } from "../game/models/deserialized-models";
import { CommandDeserializer } from "./CommandDeserializer";

export const deserializeUpgradedAbility = (
  data: Uint8Array
): UpgradedSkillDto => {
  const deserializer = new CommandDeserializer(data);

  return {
    abilityValue: deserializer.getUint16(),
    abilityPoints: deserializer.getUint16(),
  };
};
