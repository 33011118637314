import { CreatureWithPosDto } from "../game/models/deserialized-models";
import { CommandDeserializer } from "./CommandDeserializer";
import {
  readCreature,
  readGlobalPos,
} from "./helpers/backend-data-reader-helper";

export const deserializePlayerWithPos = (
  data: Uint8Array
): CreatureWithPosDto => {
  const deserializer = new CommandDeserializer(data);
  return {
    globalPosition: readGlobalPos(deserializer),
    creatureDto: readCreature(deserializer),
  };
};
