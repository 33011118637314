import { useState } from "react";
import { callLoginUser, callRegisterUser } from "../services/playerService";
import { invokeApi } from "../services/invokeApiHelper";
import { getPublicErrorMessage } from "../services/errorCodes";

interface WelcomeProps {
  onSubmit: (token: string) => void;
}

const Welcome = (props: WelcomeProps) => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [isRegistering, setIsRegistering] = useState<boolean>(false);
  const [registerSuccess, setRegisterSuccess] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string | null>(null);
  const [errors, setErrors] = useState<{
    username?: string | null;
    password?: string | null;
    confirmPassword?: string | null;
  }>({});

  const handleInput = (
    event: React.ChangeEvent<HTMLInputElement>,
    setter: React.Dispatch<React.SetStateAction<string>>
  ) => {
    setter(event.target.value);
  };

  const validateUsername = (username: string): string | null => {
    if (username.length < 3 || username.length > 20) {
      return "Username must be between 3 and 20 characters.";
    }
    if (!/^[a-zA-Z]+(?: [a-zA-Z]+)*$/.test(username)) {
      return "Username can only contain letters and single spaces.";
    }
    return null;
  };

  const validatePassword = (password: string): string | null => {
    if (password.length < 8) {
      return "Password must be at least 8 characters long.";
    }
    if (!/(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/.test(password)) {
      return "Password must contain at least one uppercase letter, one number, and one special character.";
    }
    return null;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const usernameError = validateUsername(username);
    const passwordError = validatePassword(password);
    const confirmPasswordError =
      isRegistering && password !== confirmPassword
        ? "Passwords do not match."
        : null;

    setErrors({
      username: usernameError,
      password: passwordError,
      confirmPassword: confirmPasswordError,
    });

    if (usernameError || passwordError || confirmPasswordError) {
      return;
    }

    setApiError(null);
    setRegisterSuccess(false);

    if (isRegistering) {
      await invokeApi(
        () => callRegisterUser(username, password, confirmPassword),
        (success) => {
          setApiError(null);
          clearFields();
          setRegisterSuccess(true);
          setIsRegistering(false);
        },
        (error) => {
          setApiError(error);
        }
      );
    } else {
      await invokeApi(
        () => callLoginUser(username, password),
        (data: {token: string}) => {
          props.onSubmit(data.token);
          setApiError(null);
          clearFields();
        },
        (error) => {
          setApiError(error);
        }
      );
    }
  };

  const clearFields = () => {
    setUsername("");
    setPassword("");
    setConfirmPassword("");
  };

  return (
    <div className="flex items-center justify-center h-screen w-screen bg-evoloria bg-cover bg-center max-lg:hidden">
      <div className="bg-opacity-90 bg-yellow-800 p-8 rounded-lg shadow-lg text-center max-w-xs w-full">
        <img src="/logo/logo1.png" className="mb-6" />
        <form onSubmit={handleSubmit}>
          {errors.username && (
            <p className="text-red-300 text-xs mb-2">{errors.username}</p>
          )}
          <input
            type="text"
            value={username}
            onChange={(e) => handleInput(e, setUsername)}
            placeholder="Enter your username"
            className="w-full p-3 mb-4 rounded-lg border-2 border-yellow-600 focus:outline-none focus:border-yellow-400 text-yellow-800 text-sm"
          />

          {errors.password && (
            <p className="text-red-300 text-xs mb-2">{errors.password}</p>
          )}
          <input
            type="password"
            value={password}
            onChange={(e) => handleInput(e, setPassword)}
            placeholder="Enter your password"
            className="w-full p-3 mb-4 rounded-lg border-2 border-yellow-600 focus:outline-none focus:border-yellow-400 text-yellow-800 text-sm"
          />
          {isRegistering && (
            <>
              {errors.confirmPassword && (
                <p className="text-red-300 text-xs mb-2">
                  {errors.confirmPassword}
                </p>
              )}
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => handleInput(e, setConfirmPassword)}
                placeholder="Confirm your password"
                className="w-full p-3 mb-4 rounded-lg border-2 border-yellow-600 focus:outline-none focus:border-yellow-400 text-yellow-800 text-sm"
              />
            </>
          )}
          {registerSuccess && (
            <p className="my-2 text-xs text-white">
              Your account has beed registered!
            </p>
          )}
          {apiError && (
            <p className="my-2 text-xs text-red-300">
              {getPublicErrorMessage(apiError)}
            </p>
          )}
          <button
            type="submit"
            className="w-full bg-yellow-600 hover:bg-yellow-500 text-white font-bold py-3 rounded-lg text-sm"
          >
            {isRegistering ? "Register" : "Login"}
          </button>
        </form>
        <button
          className="w-full mt-4 text-yellow-500 underline text-sm"
          onClick={() => {
            setIsRegistering((prev) => !prev);
            setErrors({
              username: null,
              password: null,
              confirmPassword: null,
            });
            setApiError(null);
            clearFields();
          }}
        >
          {isRegistering
            ? "Already have an account? Login"
            : "Don't have an account? Register"}
        </button>
        <p className="mt-4 text-xs text-white">
          version {process.env.REACT_APP_VERSION}
        </p>
      </div>
    </div>
  );
};

export default Welcome;
